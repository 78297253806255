import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Editor, EditorState, ContentState, convertToRaw, convertFromRaw, RichUtils, getDefaultKeyBinding, CompositeDecorator, Modifier, ContentBlock, genKey, DefaultDraftBlockRenderMap, SelectionState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { List } from 'immutable';
import Immutable from 'immutable';
// icons 
import { LuHeading } from "react-icons/lu";
import { BsChatLeftQuote } from "react-icons/bs";
import { VscListUnordered } from "react-icons/vsc";
import { GoListOrdered } from "react-icons/go";
import { BiBold } from "react-icons/bi";
import { PiTextItalicBold } from "react-icons/pi";
import { BiLink } from "react-icons/bi";

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { useModal } from '../../Context/ModalContext';

const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} style={{ color: '#485FC7'}}>
      {props.children}
    </a>
  );
};

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
};

const customLineBreakDecorator = (contentBlock, callback, contentState) => {
  const text = contentBlock.getText();
  const blockType = contentBlock.getType();
  const isListItem = blockType === 'unordered-list-item' || blockType === 'ordered-list-item';

  let matchArr, start;
  const regex = isListItem ? /\n/g : /\n\n/g;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + (isListItem ? 1 : 2));
  }
};

const LineBreakSpan = (props) => {
  return <br />;
};

const StyleButton = ({ onToggle, style, label, active, tooltip, isTouchDevice }) => {
  const onMouseDown = (e) => {
    e.preventDefault();
    onToggle(style);
  };

  return (
    <Tippy 
      content={tooltip}
      placement="top"
      arrow={true}
      theme='light-border'
      hideOnClick={true} 
      delay={[0, 0]} 
      touch={false}
      disabled={isTouchDevice}
    >
      <span 
        className="RichEditor-styleButton" 
        onMouseDown={onMouseDown}
        style={{ color: active ? '#267953' : '#999999' }}
      >
        {label}
      </span>
    </Tippy>
  );
};

const Image = (props) => {
  try {
    const { src } = props.contentState.getEntity(props.entityKey).getData();
    if (!src) return null;

    return (
      <div 
        style={{ 
          position: 'relative',
          display: 'block',
          cursor: 'text',
          lineHeight: 0,
        }}
      >
        <img 
          src={src} 
          alt="" 
          style={{ 
            maxWidth: '90%', 
            display: 'block',
          }} 
        />
        <span
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: 0,
            height: 0,
            lineHeight: 0,
            overflow: 'hidden'
          }}
        >
          {'\u200B'}
        </span>
      </div>
    );
  } catch (error) {
    console.error('Error rendering image:', error);
    return null;
  }
};

const findImageEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'IMAGE'
      );
    },
    callback
  );
};

const insertImage = (contentState, src) => {
  const contentStateWithEntity = contentState.createEntity(
    'IMAGE',
    'IMMUTABLE',
    { src: src }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  
  return Modifier.insertText(
    contentStateWithEntity,
    contentStateWithEntity.getSelectionAfter(),
    ' ',
    undefined,
    entityKey
  );
};

const insertImageAtIndex = (contentState, src, index) => {
  const blockMap = contentState.getBlockMap();
  let targetBlock;
  let targetOffset;

  let currentIndex = 0;
  blockMap.forEach((block) => {
    const blockLength = block.getLength();
    if (currentIndex + blockLength > index) {
      targetBlock = block;
      targetOffset = index - currentIndex;
      return false;
    }
    currentIndex += blockLength + 1;
  });

  if (!targetBlock) {
    targetBlock = blockMap.last();
    targetOffset = targetBlock.getLength();
  }

  const targetSelection = SelectionState.createEmpty(targetBlock.getKey()).merge({
    anchorOffset: targetOffset,
    focusOffset: targetOffset,
    anchorKey: targetBlock.getKey(),
    focusKey: targetBlock.getKey(),
  });

  const contentStateWithEntity = contentState.createEntity(
    'IMAGE',
    'IMMUTABLE',
    { src: src }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  return Modifier.insertText(
    contentStateWithEntity,
    targetSelection,
    ' ',
    undefined,
    entityKey
  );
};

const isSelectionOnImage = (editorState) => {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const startKey = selection.getStartKey();
  const startOffset = selection.getStartOffset();
  const block = content.getBlockForKey(startKey);
  const entityKey = block.getEntityAt(startOffset);
  
  if (entityKey) {
    const entity = content.getEntity(entityKey);
    return entity.getType() === 'IMAGE';
  }
  return false;
};

const blockRenderMap = DefaultDraftBlockRenderMap.merge(
  Immutable.Map({
    'blockquote': {
      element: 'blockquote',
      wrapper: <div className="blockquote-wrapper" />
    }
  })
);

const RichEditor = ({ value, onChange, placeholder, initialContent }) => {
  const [editorState, setEditorState] = useState(() => {
    const decorator = new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: Link,
      },
      {
        strategy: customLineBreakDecorator,
        component: LineBreakSpan,
      },
      {
        strategy: findImageEntities,
        component: Image,
      },
    ]);
    return EditorState.createEmpty(decorator);
  });
  const editorRef = useRef(null);
  const isInternalChange = useRef(false);
  const createContentState = useCallback((content) => {
    if (!content) return ContentState.createFromText('');
    
    try {
      // First, replace image markdown with placeholders to preserve positions
      const imageRegex = /!\[([^\]]*)\]\(([^)]+)\)/g;
      const images = [];
      let modifiedContent = content;
      let match;
      
      while ((match = imageRegex.exec(content)) !== null) {
        images.push({ 
          src: match[2],
          placeholder: `IMAGE_PLACEHOLDER_${images.length}`,
          fullMatch: match[0]
        });
      }

      // Replace each image markdown with its placeholder
      images.forEach(img => {
        modifiedContent = modifiedContent.replace(img.fullMatch, img.placeholder);
      });

      // Convert markdown to raw draft object
      const rawDraftObject = markdownToDraft(modifiedContent);

      if (!rawDraftObject || !rawDraftObject.blocks) {
        console.error('Invalid rawDraftObject:', rawDraftObject);
        return ContentState.createFromText(content);
      }

      // Create content state from raw object
      let contentState = convertFromRaw(rawDraftObject);

      // Replace placeholders with actual images
      images.forEach(image => {
        const blocks = contentState.getBlockMap();
        let targetBlock;
        let targetOffset;

        // Find the placeholder in blocks
        blocks.forEach((block) => {
          const text = block.getText();
          const index = text.indexOf(image.placeholder);
          if (index !== -1) {
            targetBlock = block;
            targetOffset = index;
          }
        });

        if (targetBlock) {
          // Create selection at placeholder position
          const targetSelection = SelectionState.createEmpty(targetBlock.getKey()).merge({
            anchorOffset: targetOffset,
            focusOffset: targetOffset + image.placeholder.length,
            anchorKey: targetBlock.getKey(),
            focusKey: targetBlock.getKey(),
          });

          // Remove placeholder
          contentState = Modifier.removeRange(
            contentState,
            targetSelection,
            'backward'
          );

          // Insert image entity
          const contentStateWithEntity = contentState.createEntity(
            'IMAGE',
            'IMMUTABLE',
            { src: image.src }
          );
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

          contentState = Modifier.insertText(
            contentStateWithEntity,
            contentStateWithEntity.getSelectionAfter(),
            ' ',
            undefined,
            entityKey
          );
        }
      });

      return contentState;
    } catch (error) {
      console.error('Error converting markdown to draft:', error);
      return ContentState.createFromText(content);
    }
  }, []);

  useEffect(() => {
    if (!isInternalChange.current && value) {
      const newContentState = createContentState(value);
      const newEditorState = EditorState.createWithContent(newContentState, editorState.getDecorator());
      setEditorState(newEditorState);
    }
    isInternalChange.current = false;
  }, [value, createContentState]);

  const handlePastedText = (text, html, editorState) => {
    if (text.match(/^https?:\/\/.+\.(jpeg|jpg|gif|png)$/)) {
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        'IMAGE',
        'IMMUTABLE',
        { src: text }
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
      
      const newContentState = Modifier.replaceText(
        newEditorState.getCurrentContent(),
        newEditorState.getSelection(),
        ' ',
        newEditorState.getCurrentInlineStyle(),
        entityKey
      );
      
      const finalEditorState = EditorState.push(newEditorState, newContentState, 'insert-characters');
      handleEditorChange(finalEditorState);
      return 'handled';
    }
    
    return 'not-handled';
  };

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);

    const contentState = newEditorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    
    let markdown = draftToMarkdown(rawContent, {
      entityItems: {
        IMAGE: {
          open: () => '',
          close: (entity) => `![](${entity.data.src})\n\n`,
        },
      },
      styleItems: {
        BOLD: {
          open: () => '**',
          close: () => '**',
        },
        ITALIC: {
          open: () => '_',
          close: () => '_',
        },
      },
    });

    // Clean up extra newlines around images
    markdown = markdown
      .replace(/\n{3,}/g, '\n\n')
      .replace(/\n\n$/g, '\n')
      .trim();

    isInternalChange.current = true;
    onChange(markdown);
  };

  const handleKeyCommand = (command, editorState) => {
    // Add new command handlers
    if (command === 'delete-line') {
      const contentState = editorState.getCurrentContent();
      const selection = editorState.getSelection();
      const startKey = selection.getStartKey();
      const block = contentState.getBlockForKey(startKey);
      
      const newContentState = Modifier.removeRange(
        contentState,
        selection.merge({
          anchorOffset: 0,
          focusOffset: block.getLength(),
        }),
        'backward'
      );
      
      const newEditorState = EditorState.push(editorState, newContentState, 'remove-range');
      handleEditorChange(newEditorState);
      return 'handled';
    }

    if (command === 'delete-word') {
      const contentState = editorState.getCurrentContent();
      const selection = editorState.getSelection();
      const startKey = selection.getStartKey();
      const block = contentState.getBlockForKey(startKey);
      const text = block.getText();
      const startOffset = selection.getStartOffset();
      
      // Find the start of the previous word, including spaces
      let wordStartOffset = startOffset;
      // First, skip any spaces immediately before the cursor
      while (wordStartOffset > 0 && /\s/.test(text[wordStartOffset - 1])) {
        wordStartOffset--;
      }
      // Then, continue to the start of the word
      while (wordStartOffset > 0 && !/\s/.test(text[wordStartOffset - 1])) {
        wordStartOffset--;
      }
      
      const newContentState = Modifier.removeRange(
        contentState,
        selection.merge({
          anchorOffset: wordStartOffset,
          focusOffset: startOffset,
        }),
        'backward'
      );
      
      const newEditorState = EditorState.push(editorState, newContentState, 'remove-range');
      
      // Maintain focus by forcing selection
      const newSelection = newEditorState.getSelection().merge({
        anchorOffset: wordStartOffset,
        focusOffset: wordStartOffset,
      });
      const finalEditorState = EditorState.forceSelection(newEditorState, newSelection);
      
      handleEditorChange(finalEditorState);
      return 'handled';
    }

    if (command === 'insert-new-line-after-image') {
      const contentState = editorState.getCurrentContent();
      const selection = editorState.getSelection();
      const blockKey = selection.getStartKey();
      const block = contentState.getBlockForKey(blockKey);
      
      // Split the block at cursor position
      const newContentState = Modifier.splitBlock(contentState, selection);
      
      // Push the changes to create new block
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        'split-block'
      );
      
      handleEditorChange(newEditorState);
      return 'handled';
    }

    if (command === 'split-block-and-reset-type') {
      const contentState = editorState.getCurrentContent();
      const selection = editorState.getSelection();
      
      // Split the block
      let newContentState = Modifier.splitBlock(contentState, selection);
      
      // Get the key of the new block
      const blockAfterSplit = newContentState.getBlockAfter(selection.getStartKey());
      
      // Change the type of the new block to unstyled
      newContentState = Modifier.setBlockType(
        newContentState,
        selection.merge({
          anchorKey: blockAfterSplit.getKey(),
          focusKey: blockAfterSplit.getKey(),
          anchorOffset: 0,
          focusOffset: 0,
        }),
        'unstyled'
      );
      
      const newEditorState = EditorState.push(editorState, newContentState, 'split-block');
      
      handleEditorChange(EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter()));
      return 'handled';
    }

    // Handle toggle commands
    if (command.startsWith('toggle-')) {
      const blockType = command.replace('toggle-', '');
      let newEditorState = RichUtils.toggleBlockType(editorState, blockType);

      // Remove the triggering characters
      const selection = newEditorState.getSelection();
      const content = newEditorState.getCurrentContent();
      const block = content.getBlockForKey(selection.getStartKey());
      const text = block.getText();

      let charsToRemove = 0;
      if (text.startsWith('#')) charsToRemove = 1;
      if (text.startsWith('##')) charsToRemove = 2;
      if (text.startsWith('* ')) charsToRemove = 2;
      if (text.startsWith('1. ')) charsToRemove = 3;
      if (text.startsWith('> ')) charsToRemove = 2;

      if (charsToRemove > 0) {
        const newContent = Modifier.replaceText(
          content,
          selection.merge({
            anchorOffset: 0,
            focusOffset: charsToRemove,
          }),
          ''
        );
        newEditorState = EditorState.push(newEditorState, newContent, 'remove-range');
      }

      handleEditorChange(newEditorState);
      return 'handled';
    }

    if (command === 'backspace' || command === 'delete') {
      if (isSelectionOnImage(editorState)) {
        const selection = editorState.getSelection();
        const content = editorState.getCurrentContent();
        const startKey = selection.getStartKey();
        const startOffset = selection.getStartOffset();
        const block = content.getBlockForKey(startKey);
        
        // Create a new selection that includes the image
        const imageSelection = selection.merge({
          anchorOffset: startOffset,
          focusOffset: startOffset + 1
        });
        
        // Remove the image
        const newContent = Modifier.removeRange(content, imageSelection, 'backward');
        const newEditorState = EditorState.push(editorState, newContent, 'remove-range');
        
        handleEditorChange(newEditorState);
        return 'handled';
      }
    }

    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      handleEditorChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const keyBindingFn = (e) => {
    // Add new key bindings
    if (e.keyCode === 8) { 
      if (e.metaKey || e.ctrlKey) {
        return 'delete-line';
      } else if (e.altKey) {
        return 'delete-word';
      }
    }

    if (e.key === 'Enter') {
      const selection = editorState.getSelection();
      const content = editorState.getCurrentContent();
      const block = content.getBlockForKey(selection.getStartKey());
      const currentOffset = selection.getAnchorOffset();
      
      // Check for image entity at or before cursor
      for (let i = currentOffset - 1; i >= 0; i--) {
        const entityKey = block.getEntityAt(i);
        if (entityKey) {
          const entity = content.getEntity(entityKey);
          if (entity.getType() === 'IMAGE') {
            // Only handle if cursor is right after the image
            if (currentOffset === i + 1) {
              return 'insert-new-line-after-image';
            }
            break;
          }
        }
      }

      if (block.getType() === 'header-two') {
        return 'split-block-and-reset-type';
      }
    }

    if (e.key === ' ') {
      const selection = editorState.getSelection();
      const content = editorState.getCurrentContent();
      const block = content.getBlockForKey(selection.getStartKey());
      const text = block.getText();

      let newBlockType = null;
      let charsToRemove = 0;

      if (text === '#') { newBlockType = 'header-two'; charsToRemove = 1; }
      else if (text === '*') { newBlockType = 'unordered-list-item'; charsToRemove = 1; }
      else if (text === '1.') { newBlockType = 'ordered-list-item'; charsToRemove = 2; }
      else if (text === '>') { newBlockType = 'blockquote'; charsToRemove = 1; }

      if (newBlockType) {
        e.preventDefault();  // Prevent the space from being inserted
        
        // Remove the triggering characters
        let newContent = Modifier.replaceText(
          content,
          selection.merge({
            anchorOffset: 0,
            focusOffset: charsToRemove,
          }),
          ''
        );

        // Change the block type
        newContent = Modifier.setBlockType(
          newContent,
          newContent.getSelectionAfter(),
          newBlockType
        );

        const newEditorState = EditorState.push(editorState, newContent, 'change-block-type');
        handleEditorChange(newEditorState);
        return 'handled';
      }
    } else if (e.key === 'Tab') {
      e.preventDefault();
      const selection = editorState.getSelection();
      const content = editorState.getCurrentContent();
      const block = content.getBlockForKey(selection.getStartKey());
      const blockType = block.getType();

      if (blockType === 'unordered-list-item' || blockType === 'ordered-list-item') {
        const newEditorState = RichUtils.onTab(e, editorState, 4);
        handleEditorChange(newEditorState);
        return 'handled';
      }
    } else if (e.key === 'Backspace') {
      return 'backspace';
    } else if (e.key === 'Delete') {
      return 'delete';
    }

    return getDefaultKeyBinding(e);
  };

  const toggleBlockType = (blockType) => {
    if (blockType === 'blockquote') {
      const selection = editorState.getSelection();
      const contentState = editorState.getCurrentContent();
      const startKey = selection.getStartKey();
      const endKey = selection.getEndKey();
      
      // Check if we're in a blockquote      
      let isInBlockquote = true;
      let currentKey = startKey;
    
      while (true) {
        const currentBlock = contentState.getBlockForKey(currentKey);
        if (currentBlock.getType() !== 'blockquote') {
          isInBlockquote = false;
          break;
        }
        if (currentKey === endKey) break;
        currentKey = contentState.getBlockAfter(currentKey).getKey();
      }

      if (isInBlockquote) {
        // UNQUOTING - Split into multiple blocks
        let text = '';
        let currentKey = startKey;
        
        // Collect text from all selected blocks
        while (true) {
          const currentBlock = contentState.getBlockForKey(currentKey);
          text += currentBlock.getText();
          if (currentKey === endKey) break;
          text += '\n';
          currentKey = contentState.getBlockAfter(currentKey).getKey();
        }

        const lines = text.split('\n');
        let newContentState = contentState;

        // Remove the original blockquote
        newContentState = Modifier.removeRange(
          newContentState,
          selection.merge({
            anchorKey: startKey,
            focusKey: endKey,
            anchorOffset: 0,
            focusOffset: contentState.getBlockForKey(endKey).getLength(),
            isBackward: false,
          }),
          'backward'
        );

        // Create first block
        newContentState = Modifier.setBlockType(
          newContentState,
          newContentState.getSelectionAfter(),
          'unstyled'
        );

        // Insert first line
        newContentState = Modifier.insertText(
          newContentState,
          newContentState.getSelectionAfter(),
          lines[0]
        );

        // Create remaining blocks
        for (let i = 1; i < lines.length; i++) {
          newContentState = Modifier.splitBlock(
            newContentState,
            newContentState.getSelectionAfter()
          );

          newContentState = Modifier.setBlockType(
            newContentState,
            newContentState.getSelectionAfter(),
            'unstyled'
          );

          newContentState = Modifier.insertText(
            newContentState,
            newContentState.getSelectionAfter(),
            lines[i]
          );
        }

        const newEditorState = EditorState.push(
          editorState,
          newContentState,
          'split-block'
        );

        handleEditorChange(newEditorState);
      } else {
        // QUOTING - Combine into single blockquote
        let text = '';
        let currentKey = startKey;
        
        while (true) {
          const currentBlock = contentState.getBlockForKey(currentKey);
          text += currentBlock.getText();
          if (currentKey === endKey) break;
          text += '\n';
          currentKey = contentState.getBlockAfter(currentKey).getKey();
        }

        const newSelection = selection.merge({
          anchorKey: startKey,
          focusKey: endKey,
          anchorOffset: 0,
          focusOffset: contentState.getBlockForKey(endKey).getLength(),
          isBackward: false,
        });

        let newContentState = Modifier.removeRange(
          contentState,
          newSelection,
          'backward'
        );

        newContentState = Modifier.insertText(
          newContentState,
          newContentState.getSelectionAfter(),
          text
        );

        newContentState = Modifier.setBlockType(
          newContentState,
          newContentState.getSelectionAfter(),
          'blockquote'
        );

        const newEditorState = EditorState.push(
          editorState,
          newContentState,
          'change-block-type'
        );

        handleEditorChange(newEditorState);
      }
    } else {
      const newState = RichUtils.toggleBlockType(editorState, blockType);
      if (newState) {
        handleEditorChange(newState);
      }
    }
  };

  const toggleInlineStyle = (inlineStyle) => {
    handleEditorChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const { requestLink } = useModal();
  const [pendingSelection, setPendingSelection] = useState(null);
  const [pendingEditorState, setPendingEditorState] = useState(null);

  const addLink = useCallback(() => {
    const selection = editorState.getSelection();
    if (!selection) {
      window.alert('No selection available');
      return;
    }

    const contentState = editorState.getCurrentContent();
    let existingUrl = '';

    // Get existing URL if there is one
    if (!selection.isCollapsed()) {
      const startKey = selection.getStartKey();
      const startOffset = selection.getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
      
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        if (linkInstance.getType() === 'LINK') {
          existingUrl = linkInstance.getData().url;
        }
      }
    }

    // Set the states first
    setPendingSelection(selection);
    setPendingEditorState(editorState);

    // Then call requestLink with a callback that checks the states
    requestLink(existingUrl, (url) => {
      // Get the latest pending states inside the callback
      const currentPendingSelection = selection;
      const currentPendingEditorState = editorState;

      if (!currentPendingSelection || !currentPendingEditorState) {
        return;
      }

      const contentState = currentPendingEditorState.getCurrentContent();
      let newEditorState;

      // Clear any existing link first
      const withoutLink = Modifier.applyEntity(
        contentState,
        currentPendingSelection,
        null
      );
      newEditorState = EditorState.push(currentPendingEditorState, withoutLink, 'apply-entity');

      if (url) {
        // Create and apply new link if provided
        const contentStateWithEntity = newEditorState.getCurrentContent().createEntity(
          'LINK',
          'MUTABLE',
          { url }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        if (currentPendingSelection.isCollapsed()) {
          const textWithEntity = Modifier.insertText(
            contentStateWithEntity,
            currentPendingSelection,
            url,
            null,
            entityKey
          );
          newEditorState = EditorState.push(newEditorState, textWithEntity, 'insert-characters');
        } else {
          const withLink = Modifier.applyEntity(
            contentStateWithEntity,
            currentPendingSelection,
            entityKey
          );
          newEditorState = EditorState.push(newEditorState, withLink, 'apply-entity');
        }
      }

      // Update editor state
      setEditorState(newEditorState);
      handleEditorChange(newEditorState);
    });
  }, [editorState, requestLink]);

  const focusEditor = () => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  };
  
  const isStyleActive = (style) => {
    if (style.startsWith('header-') || style === 'blockquote' || style.endsWith('-list-item')) {
      const selection = editorState.getSelection();
      const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
      return blockType === style;
    }
    return editorState.getCurrentInlineStyle().has(style);
  };

  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    setIsTouchDevice(isTouch);
  }, []);

  return (
    <div className="RichEditor-root">
      <div className="RichEditor-controls">
        <StyleButton onToggle={toggleBlockType} style="header-two" label={<LuHeading />} active={isStyleActive('header-two')} tooltip="Heading (# + space)" isTouchDevice={isTouchDevice} />
        <StyleButton onToggle={toggleBlockType} style="blockquote" label={<BsChatLeftQuote />} active={isStyleActive('blockquote')} tooltip="Blockquote (> + space)" isTouchDevice={isTouchDevice} />
        <StyleButton onToggle={toggleInlineStyle} style="BOLD" label={<BiBold />} active={isStyleActive('BOLD')} tooltip="Bold (Cmd/Ctrl + B)" isTouchDevice={isTouchDevice} />
        <StyleButton onToggle={toggleInlineStyle} style="ITALIC" label={<PiTextItalicBold />} active={isStyleActive('ITALIC')} tooltip="Italic (Cmd/Ctrl + I)" isTouchDevice={isTouchDevice} />
        <StyleButton onToggle={toggleBlockType} style="unordered-list-item" label={<VscListUnordered />} active={isStyleActive('unordered-list-item')} tooltip="Bullet List (* + space)" isTouchDevice={isTouchDevice} />
        <StyleButton onToggle={toggleBlockType} style="ordered-list-item" label={<GoListOrdered />} active={isStyleActive('ordered-list-item')} tooltip="Numbered List (1. + space)" isTouchDevice={isTouchDevice} />
        <StyleButton onToggle={addLink} style="LINK" label={<BiLink />} active={false} tooltip="Add Link" isTouchDevice={isTouchDevice} />
      </div>
      <div onClick={focusEditor} className="RichEditor-content">
        <Editor
          ref={editorRef}
          editorState={editorState}
          onChange={handleEditorChange}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={keyBindingFn}
          handlePastedText={handlePastedText}
          blockRenderMap={blockRenderMap}
        />
      </div>
    </div>
  );
};

export default React.memo(RichEditor);
