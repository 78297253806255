import React, { useState } from 'react';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';
const ResourceDescription = ({description}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const maxLength = 300; // Characters to show before truncating
    
    if (!description || description === "") {
        return (
            <div className="resource-description">
                <p style={{width:'100%', height:'100px', display:'flex', alignItems:'center', justifyContent:'center'}}>No description available</p>
            </div>
        );
    }

    const shouldTruncate = description.length > maxLength;
    const displayText = !isExpanded && shouldTruncate 
        ? description.slice(0, maxLength) + '...'
        : description;

    return (
        <div>
            <div className='subject-rel-title'>Description</div>
            <div className="resource-description" style={{overflowY: 'auto'}}>
                <div dangerouslySetInnerHTML={{ __html: displayText }} />
                {shouldTruncate && (
                    <button 
                        onClick={() => setIsExpanded(!isExpanded)}
                        className="note-expand-button"
                        style={{padding:'5px 0', marginTop:'5px'}}
                    >
                        {isExpanded ? 'Show Less' : 'Show More'}
                        {isExpanded ? <FaAngleUp style={{marginLeft: '5px', marginTop:'0px'}} /> : <FaAngleDown style={{marginLeft: '5px', marginTop:'0px'}} />} 
                    </button>
                )}
            </div>
        </div>
    );
};

export default ResourceDescription;