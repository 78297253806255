import React, { useState } from "react";
import AddToLinkoModal from "../AddModals/AddToLinkoModal";
// Icons
import { RiBook2Line } from "react-icons/ri";
import { PiGlobe } from "react-icons/pi";
import { MdOutlineLibraryAdd } from "react-icons/md";


const AddToLinko = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const openModal = (content) => {
        setModalContent(content);
        setIsModalOpen(true);
        setIsDropdownOpen(false);
    };
    const closeModal = () => setIsModalOpen(false);
    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    return (
        <span
            className="add-to-linko"
            onMouseEnter={() => !isModalOpen && setIsDropdownOpen(true)}
            onMouseLeave={() => setIsDropdownOpen(false)}
        >
            <button className='add-resources-button linko-button linko-button--primary'>
                <MdOutlineLibraryAdd size={18} /> 
                <span className="button-text">Add Resource</span>
            </button>
            {isDropdownOpen && (
                <div className="add-dropdown-menu">
                    <button
                        onClick={() => openModal("Book")}
                        className="add-option"
                    >
                        <RiBook2Line className="add-type-icon" />
                        <p className="add-type">Book</p>
                        <p className="add-type-tips">Add a book by title</p>
                    </button>
                    <button
                        onClick={() => openModal("Link")}
                        className="add-option"
                    >
                        <PiGlobe className="add-type-icon" />
                        <p className="add-type">Link</p>
                        <p className="add-type-tips">Add a resource by URL</p>
                    </button>
                </div>
            )}
            {isModalOpen && (
                <AddToLinkoModal content={modalContent} onClose={closeModal} />
            )}
        </span>
    );
};

export default AddToLinko;
