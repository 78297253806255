import React from 'react';
// Icons
import { LuShuffle, LuArrowDownWideNarrow } from "react-icons/lu";
import { RiLightbulbFlashLine } from "react-icons/ri";


const SelectNoteView = (
    {
        activeTab,
        setActiveTab, 
        setActiveSubTab
    }
) => {
    const toggleShuffle = () => {
        if (activeTab === 'revisit') {
            setActiveTab('library');
            setActiveSubTab('recent');
        } else if (activeTab === 'library') {
            setActiveTab('revisit');
            setActiveSubTab('ignite');
        }
    };

    return (
        <div className="shuffle-toggle-container">
            
            <div
                className={`toggle-button ${activeTab === 'library' ? 'toggle-active' : ''}`}
                onClick={() => toggleShuffle()}
            >
                <div className={`toggle-option ${activeTab === 'revisit' ? 'toggle-active' : ''}`}><RiLightbulbFlashLine className={`icon ${activeTab === 'revisit' ? 'icon-active' : ''}`} /><p className='hide-small toggle-text'>{activeTab === 'revisit' ? 'Ignite' : ''}</p></div>
                <div className='rx-divider-horizontal' style={{margin:'0 5px'}}/>
                <div className={`toggle-option ${activeTab === 'library' ? 'toggle-active' : ''}`}><LuArrowDownWideNarrow className={`icon ${activeTab === 'library' ? 'icon-active' : ''}`} /><p className='hide-small toggle-text'>{activeTab === 'library' ? 'Timeline' : ''}</p></div>
            </div>
        </div>
    );
};

export default SelectNoteView;