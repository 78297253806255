import axios from '../Middleware/axios';
import storageUtils from '../storageUtils';

export const fetchFollowingFeed = async (limit, exploreOffset, subjectId, resourceId, setExplore, setExploreOffset, setHasMoreExplore, setIsFetchingMoreExplore) => {
    try {
        const accessToken = await storageUtils.getItem('access_token');
        const headers = { Authorization: `Bearer ${accessToken}` };
        const payload = {
            limit,
            offset: exploreOffset,
        };

        if (subjectId !== null && subjectId !== undefined) {
            payload.subject_id = subjectId;
        }
        if (resourceId !== null && resourceId !== undefined) {
            payload.resource_id = resourceId;
        }

        const response = await axios.get('/api/feed/following_feed/', {
            headers,
            params: payload,
        });

        const results = Array.isArray(response.data.results) ? response.data.results : [];
        if (setExplore) {
            setExplore(prevExplore => [...prevExplore, ...results]);
        }
        if (setHasMoreExplore) {
            setHasMoreExplore(results.length === limit);
        }
        
        if (results.length > 0 && setExploreOffset) {
            setExploreOffset(prevOffset => prevOffset + limit);
        }
        return response;
    } catch (error) {
        console.error('Failed to fetch following feed:', error);
    } finally {
        if (setIsFetchingMoreExplore) {
            setIsFetchingMoreExplore(false);
        }
    }
};