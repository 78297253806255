import React, { useContext } from 'react';
import { UserContext } from '../../Context/AppContext';
import { HomePageContext } from '../../Context/HomePageContext';
import NoteCard from '../NoteCard/NoteCard'; 
import { getSingleNote, subjectPage } from '@linko/shared_utils';
import { LuShuffle } from "react-icons/lu";
import Loader from '../Loader';
import CustomLink from '../CustomLink';

const TabRevisit = ({ }) => {

  const { revisitSubject, revisitNote, setRevisitNote, isShufflingSubject, setIsShufflingSubject } = useContext(HomePageContext);
  const { userNoteCount } = useContext(UserContext);
  const handleShuffle = () => {
    setIsShufflingSubject(true);
  };
  
  const deleteNote = (noteId) => {
    setRevisitNote(revisitNote.filter(note => note.id !== noteId));
  };

  // const updateNoteContent = (updatedNote) => {
  //   setRevisitNote(revisitNote.map(note => note.id === updatedNote.id ? updatedNote : note));
  // };

  const updateSingleNote = (noteId) => {
    getSingleNote(noteId)
    .then((response) => {
        const updatedNote = response.data.note;
        setRevisitNote(revisitNote.map(note => note.id === noteId ? updatedNote : note));
    })
    .catch((error) => {
        console.error('Error fetching note:', error.response ? error.response.data : error.message);
    });
  }

  return (
    <div style={{width:'100%'}}>
      {userNoteCount !== 0 && (
        <div className="revisit-header">
          <div className="subject-title-container">
            <h2 className="subject-title" >
              {isShufflingSubject ? '...' : 
                <CustomLink to={subjectPage(revisitSubject.name, !revisitSubject.is_linked)}>
                  {revisitSubject?.name}
                </CustomLink>
              }
            </h2>
          </div>
          <button className="linko-button linko-button--tertiary shuffle-button" onClick={handleShuffle}>
            <LuShuffle size={18}/> Shuffle
          </button>
        </div>
      )}
      
      {!isShufflingSubject && revisitSubject?.qa && (
        <div className="revisit-qa">
          <div className="qa-question">
            <span className="qa-label">Q:</span>
            <span className="qa-text">{revisitSubject.qa.question}</span>
          </div>
          <div className="qa-answer">
            <span className="qa-label">A:</span>
            <span className="qa-text">{revisitSubject.qa.answer}</span>
          </div>
        </div>
      )}
      {isShufflingSubject ? 
        <div className='tab-content-loader'><Loader size={50}/></div>
        :
        <div className="cards-container" style={{marginBottom:'30px'}}>
          {revisitNote && revisitNote.map((note) => (
            <div className="revisit-note" key={note.id} style={{width:'100%'}}>
              <NoteCard 
                key={note.id}
                note={note}
                onDelete={deleteNote}
                // onUpdate={updateNoteContent}
                shareView={false}
                subjectId={null}
                updateSingleNote={updateSingleNote}
                revisitTab={true}
              />
            </div>
          ))}
        </div>
      }
    </div>
  );
};

export default TabRevisit;
