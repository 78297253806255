import React from 'react';
import { FaLock } from "react-icons/fa";
import { FaUnlock } from "react-icons/fa";

const PrivateToggle = ({isPrivate, handlePrivateChange}) => {

    return (
        <div className='private-checkbox' onClick={handlePrivateChange}>
            {isPrivate ? 
                <div className='linko-button linko-button--cancel'>
                    <span className='hide-small' style={{fontSize:'16px', fontWeight:'500'}}>Private</span>
                    <FaLock size={14}/>
                </div>
            :
                <div className='linko-button linko-button--cancel'>
                    <FaUnlock  size={14}/>
                </div>
            }
        </div>
    );
};

export default PrivateToggle;