import React, {useState, useEffect, useContext} from 'react';
import { useLocation } from 'react-router-dom';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { fetchResources, fetchBestUnlearned, postResourceToMine } from '@linko/shared_utils';
import { UserContext } from '../../Context/AppContext';
import { HomePageContext } from '../../Context/HomePageContext';
import SlimResourceCard from '../../Components/ResourceCard/SlimResourceCard';
import { useToast } from '../../Components/ToastNotification';

const RecentlyAdded = (
    {
        subjectId,
        topRelatedSubjects,
        userRecentResources,
        userCommonResources,
        userName
    }
) => {

    const [inProgressResources, setInProgressResources] = useState([]);
    const [finishedResources, setFinishedResources] = useState([]);
    const [topUnlearnedResources, setTopUnlearnedResources] = useState([]);
    const { safeNavigate } = useContext(NavigationPromptContext);
    const location = useLocation();
    const { setPersistentMessage } = useToast();
    const { addResourceToLibrary } = useContext(HomePageContext);

    useEffect(() => {
        const fetchUserInProgressResources = async () => {
            try {
                // Fetch in-progress resources
                const inProgressResults = await fetchResources(
                    3, 
                    0, 
                    [], 
                    'in_progress', 
                    [], 
                    [], 
                    setInProgressResources,
                    () => {}, 
                    () => {}, 
                    () => {}, 
                    null 
                );
            } catch (error) {
                console.error('Error fetching resources:', error);
                setPersistentMessage('Failed to load resources', 'error');
            }
        };

        const fetchUserFinishedResources = async () => {
            try {
                const finishedResults = await fetchResources(
                    3, 
                    0, 
                    [], 
                    'recent', 
                    [], 
                    [], 
                    setFinishedResources,
                    () => {}, 
                    () => {}, 
                    () => {}, 
                    null 
                );
            } catch (error) {
                console.error('Error fetching resources:', error);
                setPersistentMessage('Failed to load resources', 'error');
            }
        };

        const fetchSubjectTopResources = async () => {
            const response = await fetchBestUnlearned(subjectId ? [subjectId] : []);
            if (response.data.detail === "No unlearned resources found for the given subject.") {
                setTopUnlearnedResources([]);
            } else {
                setTopUnlearnedResources(response.data.slice(0, 5));   
            }
        }
        
        if (location.pathname.includes('/my_linko')) {
            fetchUserInProgressResources();
            fetchUserFinishedResources();
            fetchSubjectTopResources();
        } else if (location.pathname.includes('/s/')) {
            fetchSubjectTopResources();
        }
    }, [location.pathname]);

    const handleAddToLibrary = async (resourceId) => {
        const response = await postResourceToMine(resourceId);
        if (response.status === 201) {
            setPersistentMessage('Resource added to your library!');
            addResourceToLibrary(response.data.resource);
            safeNavigate(`/r/${response.data.resource.resource.id}`);
        } else {
            window.alert('Failed to add resource to your library, please try again.');
        }
    }

    const renderResourceSection = (resources, title) => (
        resources?.length > 0 && (
            <>
                <div className={`subject-rel-title ${title === 'Completed' ? 'mt-20' : ''}`}>{title}</div>
                <div className='recently-added-resources mt-10'>
                    {resources.length > 0 && resources.map((resource) => {
                        return (
                            <SlimResourceCard   
                                key={resource.id}
                                id={resource.resource ? resource.resource.id : resource.id}
                                resource={resource.resource ? resource.resource : resource}
                                learnHistory={resource.resource ? resource : null}
                                onResourceClick={() => safeNavigate(`/r/${resource.resource ? resource.resource.id : resource.id}`)}
                                onAddToLibrary={title === 'For You' ? () => handleAddToLibrary(resource.resource ? resource.resource.id : resource.id, resource) : null}
                            />
                        );
                    })}
                </div>
            </>
        )
    );

    return (
        <div className='recently-added'>
            {renderResourceSection(inProgressResources, 'In Progress')}
            {renderResourceSection(finishedResources, 'Recent')}
            {renderResourceSection(topUnlearnedResources, 'For You')}
            {renderResourceSection(userRecentResources, `On ${userName}'s Shelf`)}
            {renderResourceSection(userCommonResources, 'We Both Read')}
        </div>
    );
};

export default RecentlyAdded;