import React, { useState, useEffect } from 'react';
import { IoClose } from 'react-icons/io5';

const isValidUrl = (string) => {
  try {
    // Check if empty
    if (!string) return false;
    
    // Try creating a URL object
    new URL(string);
    
    // Additional check for common protocols
    return string.startsWith('http://') || string.startsWith('https://');
  } catch (err) {
    return false;
  }
};

const LinkModal = ({ isOpen, onConfirm, onCancel, existingUrl }) => {
  const [url, setUrl] = useState('');
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const initialUrl = existingUrl || '';
    setUrl(initialUrl);
    setIsValid(isValidUrl(initialUrl));
  }, [isOpen, existingUrl]);

  const handleUrlChange = (e) => {
    const newUrl = e.target.value;
    setUrl(newUrl);
    setIsValid(isValidUrl(newUrl));
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onCancel}>
      <div className="modal-content link-modal" onClick={(e) => e.stopPropagation()} style={{width: '400px'}}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
          <h4 style={{fontSize: '18px', fontWeight: '600', color: '#333', textAlign: 'center'}}>Insert Link</h4>
          <button onClick={onCancel} className="close-button" style={{marginLeft: 'auto'}}>
            <IoClose />
          </button>
        </div>
        <input
          className="link-modal-input"
          type="text"
          value={url}
          onChange={handleUrlChange}
          placeholder="Enter URL"
          autoFocus
        />
        <div className="link-modal-buttons">
          <button 
            className="linko-button linko-button--danger"
            onClick={() => onConfirm('')}
          >
            Clear Link
          </button>
          <button 
            className="linko-button linko-button--primary"
            style={{backgroundColor: isValid ? '#48c78e' : '#ccc', cursor: isValid ? 'pointer' : 'not-allowed'}}
            onClick={() => onConfirm(url)}
            disabled={!isValid}
          >
            Add Link
          </button>
        </div>
      </div>
    </div>
  );
};

export default LinkModal;