import React, { useState } from 'react';
import TabDataWrapper from '../../Components/ContentTabs/TabDataWrapper';
import HomePageRight from './HomePageRight';
import MySubjects from './MySubjects';
import AddNoteButton from '../../Components/NoteCard/AddNoteButton';

const HomePage = () => {
  const [showTextArea, setShowTextArea] = useState(true);

  return (
    <div className='page-container'>
      <div className='home-page-left'>
        <AddNoteButton 
          showTextArea={showTextArea}
          setShowTextArea={setShowTextArea}
        />
        {/* <MySubjects /> */}
        <TabDataWrapper />
      </div>
      <HomePageRight />
    </div>
  );
};

export default HomePage;