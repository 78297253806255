import React, { createContext, useState, useEffect, useContext } from 'react';
import { UserContext, AuthContext } from './AppContext';
import { fetchRevisitData, fetchSingleResource, getSingleNote, fetchMyFeed, fetchFollowingFeed } from '@linko/shared_utils';

export const HomePageContext = createContext();

export const HomePageProvider = ({ children }) => {
    
    const { userNoteCount, fetchUserSubjectData, fetchUserInfo } = useContext(UserContext);
    const { isAuthenticated } = useContext(AuthContext);

    const [revisitSubject, setRevisitSubject] = useState('');
    const [revisitNote, setRevisitNote] = useState([]);
    const [isShufflingSubject, setIsShufflingSubject] = useState(false);

    useEffect(() => {
        if (!isAuthenticated) {
            setHomePageState({
                activeTab: 'revisit',
                activeSubTab: 'ignite',
                scrollPositions: {
                    revisit: 0,
                    library: 0,
                    explore: 0
                },
                library: [],
                explore: [],
                libraryOffset: 0,
                exploreOffset: 0,
                hasMoreLibrary: true,
                hasMoreExplore: true,
                isFetchingMoreLibrary: false,
                isFetchingMoreExplore: false,
                initialLoadDone: false,
                firstLoadComplete: false
            });
            setRevisitSubject('');
            setRevisitNote([]);
            setIsShufflingSubject(true);
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated && userNoteCount !== null ) {
            setIsShufflingSubject(true);
        }
    }, [isAuthenticated, userNoteCount]);

    useEffect(() => {
        if (isAuthenticated && userNoteCount !== null && isShufflingSubject) {
          if (userNoteCount >= 1) {
            fetchRevisitData(setRevisitSubject, setRevisitNote, setIsShufflingSubject);
          } else {
            setRevisitSubject({
              id: null,
              name: 'Linko Ignite',
              is_linked: true,
              qa: {
                question: '👋 Ready to Start Your Learning Adventure?',
                answer: '✨ Welcome to Linko Ignite! Add your first note and watch as we transform it into engaging questions that make learning fun.\n\n🎯'
              }
            });
            setIsShufflingSubject(false);
          }
        }
    }, [isShufflingSubject, userNoteCount, isAuthenticated]);

    const [homePageState, setHomePageState] = useState({
        activeTab: 'revisit',
        activeSubTab: 'ignite',
        scrollPositions: {
            revisit: 0,
            library: 0,
            explore: 0
        },
        library: [],
        explore: [],
        libraryOffset: 0,
        exploreOffset: 0,
        hasMoreLibrary: true,
        hasMoreExplore: true,
        isFetchingMoreLibrary: false,
        isFetchingMoreExplore: false,
        initialLoadDone: false,
        firstLoadComplete: false
    });

    useEffect(() => {
        if (!homePageState.firstLoadComplete && userNoteCount !== null) {
            setHomePageState(prev => ({
                ...prev,
                activeTab: userNoteCount === 0 ? 'library' : 'revisit',
                activeSubTab: userNoteCount === 0 ? 'recent' : 'ignite',
                firstLoadComplete: true
            }));
        }
    }, [userNoteCount, homePageState.firstLoadComplete]);

    const limit = 10;
    
    // Single effect to handle initial data loading
    useEffect(() => {
        if (isAuthenticated && !homePageState.initialLoadDone) {
            setHomePageState(prev => ({
                ...prev,
                isFetchingMoreLibrary: true,
                isFetchingMoreExplore: true,
                initialLoadDone: true
            }));
        }
    }, [isAuthenticated]);

    // Single effect to handle all feed fetching
    useEffect(() => {
        const fetchLibrary = async () => {
            if (!isAuthenticated || !homePageState.isFetchingMoreLibrary) return;
            if (homePageState.isFetchingMoreLibrary) {
                const response = await fetchMyFeed(limit, homePageState.libraryOffset, null, null, null, null, null, null, null);
                if (Array.isArray(response.data.results)) {
                    setHomePageState(prev => ({
                        ...prev,
                        library: [...prev.library, ...response.data.results],
                        libraryOffset: prev.libraryOffset + limit,
                        hasMoreLibrary: response.data.results.length === limit,
                        isFetchingMoreLibrary: false
                    }));
                }
            }
        };

        fetchLibrary();

    }, [homePageState.isFetchingMoreLibrary, isAuthenticated]);

    useEffect(() => {
        const fetchExplore = async () => {
            if (!isAuthenticated || !homePageState.isFetchingMoreExplore) return;
            if (homePageState.isFetchingMoreExplore) {
                const response = await fetchFollowingFeed(limit, homePageState.exploreOffset, null, null, null, null, null, null);
                if (Array.isArray(response.data.results)) {
                    setHomePageState(prev => ({
                        ...prev,
                        explore: [...prev.explore, ...response.data.results],
                        exploreOffset: prev.exploreOffset + limit,
                        hasMoreExplore: response.data.results.length === limit,
                        isFetchingMoreExplore: false
                    }));
                }
            }
        };

        fetchExplore();
    }, [homePageState.isFetchingMoreExplore, isAuthenticated]);

    // Add a simple refresh function
    const refreshLibrary = () => {
        if (!isAuthenticated) return;
        setHomePageState(prev => ({
            ...prev,
            library: [],
            explore: [],
            libraryOffset: 0,
            exploreOffset: 0,
            hasMoreLibrary: true,
            hasMoreExplore: true,
            isFetchingMoreLibrary: true,
            isFetchingMoreExplore: true
        }));
    };

    const updateSingleResourceInLibrary = async (resourceId) => {
        if (!isAuthenticated) return;
        if (!Array.isArray(homePageState.library)) {
            console.warn('library is not an array:', homePageState.library);
            return;
        }
        const existingResourceIndex = homePageState.library.findIndex(
            item => item.resource?.id === resourceId
        );
        if (existingResourceIndex !== -1) {
            const updatedResource = await fetchSingleResource(resourceId);
            setHomePageState(prevState => ({
                ...prevState,
                library: Array.isArray(prevState.library)
                    ? prevState.library.map((item) => 
                        item.resource?.id === resourceId ? updatedResource.resource : item
                    )
                    : [updatedResource]
            }));
        }
    };

    const updateSingleNoteInLibrary = async (noteId) => {
        if (!isAuthenticated) return;
        const existingNoteIndex = homePageState.library.findIndex(
            item => item.id === noteId
        );
        if (existingNoteIndex !== -1) {
            try {
                const response = await getSingleNote(noteId);
                const updatedNote = response.data.note;
                setHomePageState(prevState => ({
                    ...prevState,
                    library: Array.isArray(prevState.library)
                        ? prevState.library.map((item) => 
                            item.id === noteId ? updatedNote : item
                        )
                        : [updatedNote]
                }));
            } catch (error) {
                console.error('Error updating note in library:', error);
            }
        }
    };

    const addNoteToLibrary = async (note) => {
        if (!isAuthenticated) return;
        setHomePageState(prevState => ({
            ...prevState,
            library: Array.isArray(prevState.library) 
                ? [note, ...prevState.library]
                : [note]
        }));
        if (note?.resource?.length > 0) {
            const resourceId = Array.isArray(note.resource) && note.resource.length > 0
                ? note.resource[0].id
                : null;
            
            if (resourceId && resourceId !== null) {
                updateSingleResourceInLibrary(resourceId);
            }
        }
    };

    const addResourceToLibrary = async (resource) => {
        if (!isAuthenticated) return;
        setHomePageState(prevState => ({
            ...prevState,
            library: Array.isArray(prevState.library) 
                ? [resource, ...prevState.library]
                : [resource]
        }));
        await fetchUserSubjectData();
        await fetchUserInfo();
    };

    const removeItemFromHomePage = (itemId, type) => {
        if (type === 'note') {
            setHomePageState(prevState => ({
                ...prevState,
                library: prevState.library.filter(item => !item.note || item.id !== itemId)
            }));
            setRevisitNote(prevState => prevState.filter(item => item.id !== itemId));
        } else if (type === 'resource') {
            setHomePageState(prevState => ({
                ...prevState,
                library: prevState.library.filter(item => item.note || item.resource.id !== itemId)
            }));
        }
    };

    const updateLibraryArray = (newLibrary) => {
        setHomePageState(prevState => ({
            ...prevState,
            library: typeof newLibrary === 'function' 
                ? newLibrary(prevState.library) 
                : newLibrary
        }));
    };

    const updateExploreArray = (newExplore) => {
        setHomePageState(prevState => ({
            ...prevState,
            explore: typeof newExplore === 'function' 
                ? newExplore(prevState.explore) 
                : newExplore
        }));
    };

    const setIsFetchingMoreLibraryHomePage = (value) => {
        setHomePageState(prevState => ({
            ...prevState,
            isFetchingMoreLibrary: value
        }));
    };

    const setIsFetchingMoreExploreHomePage = (value) => {
        setHomePageState(prevState => ({
            ...prevState,
            isFetchingMoreExplore: value
        }));
    };
    
    return (
        <HomePageContext.Provider value={{ 
            homePageState, 
            setHomePageState, 
            refreshLibrary, 
            
            updateLibraryArray,
            updateExploreArray,
            setIsFetchingMoreLibraryHomePage,
            setIsFetchingMoreExploreHomePage,

            revisitSubject, 
            revisitNote, 
            setRevisitNote, 
            isShufflingSubject, 
            setIsShufflingSubject, 

            addNoteToLibrary,
            addResourceToLibrary,
            updateSingleNoteInLibrary,
            updateSingleResourceInLibrary,
            removeItemFromHomePage
        }}>
            {children}
        </HomePageContext.Provider>
    );
};
