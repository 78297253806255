import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import { createPortal } from "react-dom";
import { HomePageContext } from '../../Context/HomePageContext';
import { IoClose } from "react-icons/io5";
import Loader from "../Loader";
import ResourceTypeIcon from "../ResourceCard/ResourceTypeIcon";
import LinkResourceSuggested from "./LinkResourceSuggested";
import AddToLinkoModal from "../AddModals/AddToLinkoModal";
import { RiBook2Line } from "react-icons/ri";
import { PiGlobe } from "react-icons/pi";
import { searchMyResources, putNote } from "@linko/shared_utils";
import ModalOverlay from '../ModalOverlay';

const modalRoot = document.getElementById('modal-root') || document.body;

const LinkResourceModal = ({ 
    onClose, 
    noteResource, 
    setNoteResource, 
    noteId, 
    updateSingleNote 
}) => {

    const [submissionLoading, setSubmissionLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [selectedResource, setSelectedResource] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [componentToRender, setComponentToRender] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(true);
    const timeoutId = useRef();
    const inputRef = useRef();
    const modalRef = useRef();

    // New state for managing unlink action
    const [currentResource, setCurrentResource] = useState(noteResource);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                onClose();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [modalRef, onClose]);

    useEffect(() => {
        inputRef.current && inputRef.current.focus(); 
    }, []);

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleEnterKey = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
    };

    const handleSubmit = async () => {
        setSubmissionLoading(true);
        await putNote(noteId, null, null, null, selectedResource.id);
        setNoteResource(selectedResource);
        if (updateSingleNote) {
            updateSingleNote(noteId);
        }
        setSubmissionLoading(false);
        onClose();
    };

    useEffect(() => {
        if (selectedResource) {
            if (noteId) {
                handleSubmit();
            } else {
                setNoteResource(selectedResource);
                onClose();
            }
        }
    }, [selectedResource]);

    useEffect(() => {
        if (searchTerm === "") {
            setIsLoading(false);
            setSearchResults([]);
        } else if (searchTerm !== "") {
            setIsLoading(true);
            timeoutId.current && clearTimeout(timeoutId.current);
            timeoutId.current = setTimeout(async () => {
                try {
                    const data = await searchMyResources(searchTerm);
                    setSearchResults(data);
                    setIsLoading(false);
                } catch (error) {
                    setIsLoading(false);
                }
            }, 1000);
        } 
        return () => clearTimeout(timeoutId.current);
    }, [searchTerm]);
    
    const handleUnlink = async () => {
        setSubmissionLoading(true);
        try {
            await putNote(noteId, null, null, null, 0);
            setNoteResource(null);
            if (updateSingleNote) {
                updateSingleNote(noteId);
            }
            setCurrentResource(null);
            onClose();
        } catch (error) {
            console.error('Failed to unlink resource:', error);
        } finally {
            setSubmissionLoading(false);
        }
    };

    // Filter out the currentResource from searchResults
    const filteredSearchResults = searchResults.filter(resource => resource.id !== currentResource?.id);

    const renderMainModalContent = () => (
        <ModalOverlay onClose={onClose}>
            <div className="add-to-linko-modal" onClick={(e) => e.stopPropagation()}>
                <div className="add-modal link-resource-modal" ref={modalRef}>
                    {submissionLoading ? <Loader /> : (
                        <>
                        <div className="add-modal-header">
                            <h2 className="add-type-title" style={{color:'#4a4a4a'}}>Link Resource</h2>
                            <button className="close-button">
                                <IoClose onClick={onClose} />
                            </button>
                        </div>
                        <form className="add-book-form">
                            <label style={{ width: "100%" }}>
                                <input
                                    className="search-books"
                                    type="text"
                                    placeholder="Search from your library"
                                    ref={inputRef}
                                    value={searchTerm}
                                    onChange={handleSearchTermChange}
                                    onKeyDown={handleEnterKey} 
                                    required
                                />
                            </label>
                        </form>
                        <div className="link-new-resource">
                            <span>Add new: </span>
                            <button 
                            className="linko-button linko-button--tertiary"
                            onClick={() => {setComponentToRender('Book'), setIsModalVisible(false)}}>
                                <RiBook2Line size={16} />
                                Book
                            </button>
                            <button 
                            className="linko-button linko-button--tertiary"
                            onClick={() => {setComponentToRender('Link'), setIsModalVisible(false)}}>
                                <PiGlobe size={16} />
                                Link
                            </button>
                        </div>
                        <div className="book-search-results">
                            {searchTerm === "" ? (
                                <LinkResourceSuggested 
                                    setSelectedResource={setSelectedResource}
                                    currentResource={currentResource}
                                    handleUnlink={handleUnlink}
                                />
                            ) : isLoading ? (
                                <div className="search-loader">
                                    <Loader />
                                </div>
                            ) : (
                                filteredSearchResults.map((result) =>
                                    <div
                                        key={result.id}
                                        className="search-results-resource"
                                        onClick={() => setSelectedResource(result)}
                                    >
                                        <ResourceTypeIcon type={result.type} size={16}/>
                                        {result.title}
                                    </div>
                                )
                            )}
                        </div>
                        </>
                    )}
                </div>
            </div>
        </ModalOverlay>
    );

    return createPortal(
        <>
            {isModalVisible && renderMainModalContent()}
            {(componentToRender === 'Book' || componentToRender === 'Link') && (
                <AddToLinkoModal
                    setSelectedResource={setSelectedResource}
                    selectedResource={selectedResource}
                    onClose={() => {
                        setComponentToRender(null);
                        setIsModalVisible(true);
                    }}
                    content={componentToRender}
                />
            )}
        </>,
        modalRoot
    );
};

export default LinkResourceModal;
