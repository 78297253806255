import React, { useState, useEffect } from 'react';
import { MdOutlineLibraryAdd } from 'react-icons/md';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import ResourceThumbnailPlaceholder from '../AddModals/ResourceThumbnailPlaceholder';
import { cleanAuthorName, convertResourceType } from '@linko/shared_utils';
import CustomLink from '../CustomLink';
import ResourceProgress from './ResourceProgress';

const SlimResourceCard = ({ 
    id, 
    resource,
    learnHistory,
    onResourceClick, 
    onAddToLibrary 
}) => {
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    setIsTouchDevice(isTouch);
  }, []);

  return (
    <div className='slim-resource-card'>
      <div className='slim-resource-card-content'>

        {resource?.image_link && !imageError ? 
          <div style={{ width: '50px', height: '80px', flexShrink: 0, borderRadius: '4px', overflow: 'hidden' }}>
            <img 
              src={resource.image_link} 
              alt={resource.title} 
              style={{ 
                width: '100%', 
                height: '100%', 
                objectFit: 'cover'
              }} 
              onClick={onResourceClick}
              onError={() => setImageError(true)}
            />
          </div>
        : <ResourceThumbnailPlaceholder type={resource.type} width={"50px"} height={"80px"} onClick={onResourceClick}/>
        }
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', minHeight: '80px' }}>
          <div>
            <p style={{
              fontSize: '12px',
              color: '#999999'
            }}>
              {convertResourceType(resource.type).toUpperCase()}
            </p>
            {id === null ? (
              <p 
                className='slim-resource-card-title' 
                style={{
                  WebkitLineClamp: learnHistory ? 1 : 2,
                  lineClamp: learnHistory ? 1 : 2,
                  maxHeight: learnHistory ? '1.2em' : '2.4em'
                }}
                onClick={onResourceClick}
              >
                {resource.title}
              </p>
            ) : (
              <CustomLink
                to={`/r/${id}`}
                className='slim-resource-card-title'
                style={{
                  WebkitLineClamp: learnHistory ? 1 : 2,
                  lineClamp: learnHistory ? 1 : 2,
                  maxHeight: learnHistory ? '1.2em' : '2.4em'
                }}
              >
                {resource.title}
              </CustomLink>
            )}
          </div>
          <div 
            className='slim-resource-card-author'
            style={{
              WebkitLineClamp: learnHistory ? 1 : 2,
              lineClamp: learnHistory ? 1 : 2,
              maxHeight: learnHistory ? '1.2em' : '2.4em'
            }}
          >
            {`${resource.author ? cleanAuthorName(resource.author) : 'Unknown Author'}${resource.year ? `, ${resource.year}` : ''}`}
          </div>
          {learnHistory && (
            <ResourceProgress 
              id={resource.id}
              resource={resource}
              learnHistory={learnHistory}
              onUpdate={() => {}}
            />
          )}
        </div>
      </div>
      {onAddToLibrary && (
        <Tippy 
          content={'Add to my library'}
          placement="left"
          arrow={true}
          theme='light-border'
          touch={false}
          disabled={isTouchDevice}
        >
          <div 
            className='add-resource-icon' 
            onClick={(e) => {
              e.stopPropagation(); 
              onAddToLibrary();
            }}  
          >
            <MdOutlineLibraryAdd size={22} style={{ marginTop: '3px' }} />
          </div>
        </Tippy>
      )}
    </div>
  );
};

export default SlimResourceCard;
