import React, {useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../Components/ToastNotification';
import { FollowContext, AuthContext } from '../../Context/AppContext';
import { GoPersonAdd, GoPeople } from "react-icons/go";
import { sendFollowRequest } from '@linko/shared_utils';
import CustomAvatar from '../../Components/CustomAvatar';

const PublicProfileHeader = ({
    userId,
    userFirstName,
    userLastName,
}) => {
    const {requesting, followers, followings} = useContext(FollowContext);
    const {isAuthenticated} = useContext(AuthContext);
    const [friendStatus, setFriendStatus] = useState(null);
    const [notFollowing, setNotFollowing] = useState(true);
    const [followStatus, setFollowStatus] = useState(null);
    const [comparingDone, setComparingDone] = useState(false);
    const navigate = useNavigate();
    const {addToast} = useToast();

    useEffect(() => {
        try {
            if (userFirstName !== null) {
                if (followers.some(follower => follower.id === userId) && !followings.some(following => following.id === userId)) {
                    setFriendStatus(`${userFirstName} is following you`);
                    setNotFollowing(true);
                    setFollowStatus('Request follow');
                } else if (followings.some(following => following.id === userId) && !followers.some(follower => follower.id === userId)) {
                    setFriendStatus(`You are following ${userFirstName}`);
                    setNotFollowing(false);
                    setFollowStatus('Following');
                } else if (followers.some(follower => follower.id === userId) && followings.some(following => following.id === userId)) {
                    setFriendStatus(`You and ${userFirstName} are following each other`);
                    setNotFollowing(false);
                    setFollowStatus('Friends');
                } else if (requesting.some(request => request.id === userId)) {
                    setFriendStatus(`Follow request pending`);
                    setNotFollowing(true);
                    setFollowStatus('Request pending');
                } else {
                    setFriendStatus(null);
                    setNotFollowing(true); 
                    setFollowStatus('Request follow');
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setComparingDone(true);
        }
    }, [requesting, userId, userFirstName, followers, followings]);

    const handleAddFriend = async () => {
        if (isAuthenticated) {
            const response = await sendFollowRequest(userId);
            if (response.status === 200 ) {
                setFriendStatus(`Follow request pending`);
                addToast('Follow request sent');
            } else {
                window.alert('Failed to send follow request, please try again.');
            }
        } else {
            navigate('/login');
        }
    }

    return (
        <div className='profile-left-header'>
            {userFirstName && comparingDone &&
                <>
                    <div className='header-group'>
                        <CustomAvatar size={45} firstName={userFirstName} lastName={userLastName} />
                        <div className='user-name-group'>
                            <h1 className='subject-name'>{userFirstName} {userLastName}</h1>
                            <p>{friendStatus}</p>
                        </div>
                        <button 
                            className='add-friend-icon linko-button linko-button--tertiary' 
                            title='Request follow' 
                            onClick={handleAddFriend}
                            disabled={followStatus !== 'Request follow'}
                            style={{cursor: followStatus !== 'Request follow' ? 'auto' : 'pointer'}}
                        >
                            <GoPersonAdd />
                            <span>{followStatus}</span>
                        </button>
                        
                    </div>
                </>
            }
        </div>
    );
};

export default PublicProfileHeader;