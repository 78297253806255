import React, { useState, useEffect, useContext } from 'react'
import { LuTrophy } from "react-icons/lu";
import { PiTrophyDuotone } from "react-icons/pi";
import { PiCrownDuotone } from "react-icons/pi";
import { LuCrown } from "react-icons/lu";


import { UserContext, FollowContext } from '../../Context/AppContext';
import { GoPersonAdd, GoPeople } from "react-icons/go";
import { sendFollowRequest, profilePage } from '@linko/shared_utils';
import Tippy from '@tippyjs/react';
import { useToast } from '../../Components/ToastNotification';
import CustomLink from '../../Components/CustomLink';
export default function LeaderBoard({topUsers}) {

    const { followings } = useContext(FollowContext);
    const { userInfo } = useContext(UserContext);
    const { setPersistentMessage } = useToast(); 
    const [isTouchDevice, setIsTouchDevice] = useState(false);

    useEffect(() => {
        const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        setIsTouchDevice(isTouch);
    }, []);

    const requestFollow = async (userId) => {
        await sendFollowRequest(userId);
        setPersistentMessage('Follow request sent');
    }


    if (topUsers && topUsers.length === 0) return null;

    return (
        <div style={{margin:'10px 0'}}>
            <div className='subject-rel-title' style={{marginBottom:'5px'}}>Leaderboard</div>
            {topUsers && topUsers.map((user, index) => (
                <div className='leaderboard-user-container' key={user.id}>
                    <LuCrown size={24} color={index === 0 ? '#48c78e' : index === 1 ? '#8cc665' : '#d6e685'} />
                    <div className='leaderboard-user-name hover-green'>
                        <CustomLink
                            to={profilePage(user.id, user.first_name, user.last_name)}
                        >
                            {user.first_name} {user.last_name}
                        </CustomLink>
                    </div>
                    <div className='leaderboard-user-score'>
                        {user.note_count} note{user.note_count > 1 ? 's':''}
                    </div>
                    {user.id !== userInfo?.id && (
                        !followings.some(following => following.id === user.id) ?
                        <Tippy 
                            content={'Send follow request'}
                            placement="left"
                            arrow={true}
                            theme='light-border'
                            delay={[0, 0]}
                            touch={false}
                            disabled={isTouchDevice}
                        >
                            <div className='follow-icon' onClick={() => requestFollow(user.id)}>
                                <GoPersonAdd size={24} />
                            </div>
                        </Tippy>
                        :
                        <Tippy 
                            content={(<div>You already followed {user.first_name} {user.last_name}</div>)}
                            placement="left"
                            arrow={true}
                            theme='light-border'
                            delay={[0, 0]}
                        >
                            <div className='follow-icon' >
                                <GoPeople size={24} />
                            </div>
                        </Tippy>
                    )}
                </div>
            ))}
        </div>
    )
}    


