import React, {useEffect, useState} from 'react';
import BookPreview from './BookPreview';
import VideoPlayer from './VideoPlayer';
import PodcastPlayer from './PodcastPlayer';
import ArticleReader from './ArticleReader';
import FramePreview from "./FramePreview";
import ResourceDescription from './ResourceDescription';
import BookOverview from './BookOverview';
import WholeScreenModal from '../../Components/WholeScreenModal';
import { parseUrl, loadBookPreview } from './parseUrl';
import RecommendResources from './RecommendResources';

const ResourcePageRightTabs = ({ 
    resource, 
    articleContent, 
    embeddable, 
    authorInfo,
    recommendations
}) => {

    const {
        id,
        title,
        author = "",
        type,
        description,
        month,
        year,
        link: url,
        platform,
        image_link
    } = resource;

    const [tabNames, setTabNames] = useState([]);
    const [tab, setTab] = useState('');
    const [omitDescription, setOmitDescription] = useState(false);
    const [omitPreview, setOmitPreview] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1089);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const bookUrl = new URL(url);
    const bookId = bookUrl.pathname.split('/').pop();
    const isBook = type === "bo";
    const isVideo = type === "vi";
    const isArticle = type === "ar";
    const isPodcast = type === "po";

    // Check if screen is small
    useEffect(() => {
        const handleResize = () => {
          setIsSmallScreen(window.innerWidth < 1089);
        };
    
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleTabClick = (content) => {
        if (isSmallScreen) {
            setTab(content);
            setModalContent(content);
            setIsModalOpen(true);
        } else { 
            setTab(content);
        }
    };

    const { isYouTube, videoId, isPDF } = parseUrl(url);

    useEffect(() => {
        if (isBook && embeddable) {
            const cleanup = loadBookPreview(bookId, setLoaded, setOmitPreview);
            return cleanup;
        }
    }, [bookId]);

    useEffect(() => {
        let newTabNames = [];
        if (isBook) {
            newTabNames.push('Overview');
            newTabNames.push('For You');
        } else if (isVideo) {
            newTabNames.push('Watch video');
            newTabNames.push('For You');
        } else if (isPodcast) {
            newTabNames.push('Listen');
            newTabNames.push('For You');
        } else if (isArticle && !isPDF) {
            if (!omitPreview) newTabNames.push('Read');
            if (!omitDescription) newTabNames.push('For You');
        } else if (isArticle && isPDF) {
            if (!omitPreview) newTabNames.push('Preview PDF');
            if (!omitDescription) newTabNames.push('For You');
        } else {
            if (!omitDescription) newTabNames.push('Description');
            if (!omitPreview) newTabNames.push('For You');
        }
        setTabNames(newTabNames);
    }, [isBook, isVideo, isPodcast, isArticle, isPDF, omitDescription, omitPreview]);

    useEffect(() => {
        if (tabNames.length > 0) {
            if (window.innerWidth >= 1089) {
                setTab(tabNames[0]);
            } else {
                setTab('');
            }
        } 
    }, [tabNames]);

    useEffect(() => {
        if (description === null || description === '') {
            setOmitDescription(true);
        }
    }, [description]);

    if ((description === null || description === '') && embeddable === false && articleContent === null && !isBook && recommendations.length === 0) {
        return null;
    }
    if ((isArticle && !embeddable) || (!embeddable && !isBook && description === null) && recommendations.length === 0) {
        return null;
    }

    const renderModalContent = () => {
        switch (modalContent) {
            case 'Overview':
                return <BookOverview title={title} description={description} author={author} setTab={handleTabClick} embeddable={embeddable} authorInfo={authorInfo} />;
            case 'Preview':
                return <BookPreview embeddable={embeddable} loaded={loaded} bookId={bookId} setTab={handleTabClick}/>;
            case 'Description':
                return <ResourceDescription description={description} />;
            case 'Watch video':
                return <VideoPlayer videoId={videoId} videoUrl={url} setOmitPreview={setOmitPreview} description={description}/>;
            case 'Listen':
                return <PodcastPlayer url={url} setOmitPreview={setOmitPreview} description={description}/>;
            case 'Read':
                return <ArticleReader url={url} articleContent={articleContent} resource={resource}/>;
            case 'Preview PDF':
                return <FramePreview url={url} setOmitPreview={setOmitPreview}/>;
            case 'For You':
                return <RecommendResources resourceId={id} recommendations={recommendations}/>;
            default:
                return null;
        }
    };

    return (
        <div className='resource-page-right-wrapper'>
            {isSmallScreen && 
                <div className='book-tabs'>
                    {tabNames.map((tabName, index) => (
                        <button 
                            key={index}
                            className={`tab-button ${tab === tabName ? 'active' : ''}`}
                            onClick={() => handleTabClick(tabName)}
                        >
                            {tabName}
                        </button>
                    ))}
                </div>
            }
            {!isSmallScreen && 
                <div style={{width:'100%'}}>
                    {tab === 'Overview' && <BookOverview title={title} description={description} author={author} setTab={setTab} embeddable={embeddable} authorInfo={authorInfo}/>}  
                    {tab === 'Preview' && <BookPreview embeddable={embeddable} loaded={loaded} bookId={bookId} setTab={setTab}/>}
                    {tab === 'Description' && <ResourceDescription description={description}/>}
                    {tab === 'Watch video' && <VideoPlayer videoId={videoId} videoUrl={url} setOmitPreview={setOmitPreview} description={description}/>}
                    {tab === 'Listen' && <PodcastPlayer url={url} setOmitPreview={setOmitPreview} description={description}/>}
                    {tab === 'Read' && <ArticleReader url={url} articleContent={articleContent} resource={resource}/>}
                    {tab === 'Preview PDF' && <FramePreview url={url} setOmitPreview={setOmitPreview}/>}
                </div>
            }
            {!isSmallScreen && 
                <RecommendResources resourceId={id} recommendations={recommendations}/>
            }
            <WholeScreenModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                {renderModalContent()}
            </WholeScreenModal>
        </div>
    );
};

export default ResourcePageRightTabs;