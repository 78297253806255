import React, {useState, useEffect, useRef} from 'react';
import YouTube from 'react-youtube';
import { FaAngleUp, FaAngleDown } from "react-icons/fa";

const VideoPlayer = ({videoId, videoUrl, setOmitPreview, description}) => {
    const [width, setWidth] = useState('100%');
    const playerRef = useRef(null);
    const containerRef = useRef(null);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [playerError, setPlayerError] = useState(null);

    const updatePlayerSize = () => {
        if (containerRef.current) {
            const containerWidth = containerRef.current.offsetWidth;
            setWidth(containerWidth);
        }
    };

    useEffect(() => {
        updatePlayerSize();
        window.addEventListener('resize', updatePlayerSize);
        return () => {
            window.removeEventListener('resize', updatePlayerSize);
        };
    }, []);

    const handleError = (error) => {
        setPlayerError(error);
        setOmitPreview(true);
    };

    // Video platform handlers
    const videoHandlers = {
        youtube: {
            match: (url) => {
                const patterns = [
                    /(?:youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/embed\/)([^&\n?#]+)/,
                    /youtube\.com\/shorts\/([^&\n?#]+)/
                ];
                for (let pattern of patterns) {
                    const match = url?.match(pattern);
                    if (match) return match[1];
                }
                return null;
            },
            embed: (id) => (
                <YouTube
                    videoId={id}
                    opts={{
                        width: '100%',
                        height: `${width * 0.5625}px`,
                        playerVars: {
                            autoplay: 0,
                            controls: 1,
                            rel: 0,
                            showinfo: 0
                        }
                    }}
                    onReady={(event) => {
                        playerRef.current = event.target;
                    }}
                    onError={() => handleError('Failed to load YouTube video')}
                />
            )
        },
        bilibili: {
            match: (url) => {
                const patterns = [
                    /\/video\/(BV[\w]+)/,
                    /\/video\/(av\d+)/
                ];
                for (let pattern of patterns) {
                    const match = url?.match(pattern);
                    if (match) return match[1];
                }
                return null;
            },
            embed: (id) => (
                <iframe
                    src={`https://player.bilibili.com/player.html?bvid=${id}&high_quality=1&danmaku=0`}
                    frameBorder="0"
                    allowFullScreen
                    width="100%"
                    height={`${width * 0.5625}px`}
                    scrolling="no"
                    onError={() => handleError('Failed to load Bilibili video')}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
            )
        },
        vimeo: {
            match: (url) => {
                const match = url?.match(/(?:vimeo\.com\/|player\.vimeo\.com\/video\/)(\d+)/);
                return match ? match[1] : null;
            },
            embed: (id) => (
                <iframe
                    src={`https://player.vimeo.com/video/${id}`}
                    frameBorder="0"
                    allowFullScreen
                    width="100%"
                    height={`${width * 0.5625}px`}
                    onError={() => handleError('Failed to load Vimeo video')}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
            )
        },
        dailymotion: {
            match: (url) => {
                const match = url?.match(/(?:dailymotion\.com\/(?:video\/|embed\/)?|dai\.ly\/)([a-zA-Z0-9]+)/);
                return match ? match[1] : null;
            },
            embed: (id) => (
                <iframe
                    src={`https://www.dailymotion.com/embed/video/${id}`}
                    frameBorder="0"
                    allowFullScreen
                    width="100%"
                    height={`${width * 0.5625}px`}
                    onError={() => handleError('Failed to load Dailymotion video')}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
            )
        },
        generic: {
            match: (url) => {
                const videoExtensions = /\.(mp4|webm|ogg|m3u8)$/i;
                return videoExtensions.test(url) ? url : null;
            },
            embed: (url) => (
                <video
                    controls
                    width="100%"
                    height={`${width * 0.5625}px`}
                    onError={() => handleError('Failed to load video file')}
                    playsInline
                >
                    <source src={url} />
                    Your browser does not support the video tag.
                </video>
            )
        }
    };

    const renderVideoPlayer = () => {
        try {
            // If we have a YouTube videoId from props, use it directly
            if (videoId) {
                return videoHandlers.youtube.embed(videoId);
            }

            // If we have a videoUrl, try to match it with our handlers
            if (videoUrl) {
                for (const [platform, handler] of Object.entries(videoHandlers)) {
                    const id = handler.match(videoUrl);
                    if (id) {
                        return handler.embed(id);
                    }
                }
            }

            // If no match found, show error
            handleError('Unsupported video format');
            return null;
        } catch (error) {
            handleError('Error loading video player');
            return null;
        }
    };

    const formatDescription = (text) => {
        const timestampRegex = /^(\d{1,2}):(\d{2})(?::(\d{2}))?\s*(.*)/;
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        
        const lines = text.split('\n');
        let index = [];
        let remainingDescription = [];

        for (const line of lines) {
            if (timestampRegex.test(line)) {
                index.push(line);
            } else {
                remainingDescription.push(line);
            }
        }

        const formatLine = (line) => {
            return line
                .replace(timestampRegex, (match, hours, minutes, seconds, content) => {
                    const totalSeconds = seconds
                        ? parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds)
                        : parseInt(hours) * 60 + parseInt(minutes);
                    const formattedTime = seconds
                        ? `${hours}:${minutes}:${seconds}`
                        : `${hours}:${minutes}`;
                    return `<a href="#" class="timestamp" data-seconds="${totalSeconds}">${formattedTime}</a> ${content || ''}`;
                })
                .replace(urlRegex, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer" class="external-link">${url}</a>`);
        };

        const formattedIndex = index.map(formatLine).join('\n');
        const formattedDescription = remainingDescription.map(formatLine).join('\n');

        const visibleDescription = showFullDescription ? formattedDescription : formattedDescription.split('\n').slice(0, 3).join('\n');

        return (
            <div>
                {index.length > 0 && (
                    <div
                        dangerouslySetInnerHTML={{ __html: formattedIndex }}
                        onClick={handleTimestampClick}
                        style={descriptionStyle}
                    />
                )}
                {remainingDescription.length > 0 && (
                    <>
                        <div
                            dangerouslySetInnerHTML={{ __html: visibleDescription }}
                            onClick={handleTimestampClick}
                            style={descriptionStyle}
                        />
                        {remainingDescription.length > 3 && (
                            <button 
                                onClick={() => setShowFullDescription(!showFullDescription)} 
                                className='note-expand-button' 
                                style={{padding:'5px 0'}}
                            >
                                {showFullDescription ? 'Show less' : 'Show more'}
                                {showFullDescription ? <FaAngleUp style={{marginLeft: '5px', marginTop:'0px'}} /> : <FaAngleDown style={{marginLeft: '5px', marginTop:'0px'}} />} 
                            </button>
                        )}
                    </>
                )}
            </div>
        );
    };

    const handleTimestampClick = (e) => {
        if (e.target.classList.contains('timestamp')) {
            e.preventDefault();
            const seconds = parseInt(e.target.dataset.seconds, 10);
            if (playerRef.current) {
                playerRef.current.seekTo(seconds);
            }
        }
    };

    const descriptionStyle = {
        marginTop: '30px',
        width: '100%',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        lineHeight: '1.5',
        fontSize: '16px'
    };

    return (
        <div ref={containerRef} style={{ width: '100%' }}>
            <div className='subject-rel-title' style={{marginTop: '0px'}}>Watch</div>
            {renderVideoPlayer()}
            {playerError && (
                <div className="error-message" style={{ color: 'red', marginTop: '10px' }}>
                    {playerError}
                </div>
            )}
            {description && (
                <div style={descriptionStyle}>
                    {formatDescription(description)}
                </div>
            )}
        </div>
    );
};

export default VideoPlayer;
