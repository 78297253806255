import React from 'react';
import { useLocation } from 'react-router-dom';
import AddToLinko from '../NavAndFooter/AddToLinko';
import { MdOutlineLibraryAdd } from 'react-icons/md';

const NoContentPlaceholder = ({ activeTab }) => {

    const location = useLocation();

    if (location.pathname.startsWith('/my_linko') && (activeTab === 'library' || activeTab === 'revisit')) {
        return (
            <div className='linko-card mt-5' >
                <div className='no-content-placeholder'>
                    <h1>🎉 Welcome to Linko!</h1>
                    <p style={{marginBottom:'12px'}}>Thank you for being an early explorer of Linko! We're thrilled to have you on board. Here are three fun steps to kickstart your learning adventure:</p>
                    <ul style={{lineHeight:'1.5', listStyleType:'none', padding:0, fontSize:'16px'}}>
                        <li style={{marginBottom:'8px'}}>
                            📚 <span style={{fontWeight:'bold'}}>Collect Wisdom:</span> Click <AddToLinko /> to add books or online contents like YouTube videos, Medium articles, or Spotify podcasts through url.
                        </li>
                        <li style={{marginBottom:'8px'}}>✍️ <span style={{fontWeight:'bold'}}>Capture Insights:</span> Let your thoughts flow - our AI assistant will help link them into structured knowledge you can revisit anytime.</li>
                        <li style={{marginBottom:'8px'}}>🌟 <span style={{fontWeight:'bold'}}>Grow Together:</span> Explore the fields you are interested in and find inspiration from others' learning journeys.</li>
                    </ul>
                </div>
            </div>
        );
    }
    
    if (location.pathname.startsWith('/my_linko') && activeTab === 'explore') {
        return (
            <div className='linko-card mt-5'>
                <div className='no-content-placeholder'>
                    <h1>🔍 Discover Amazing Learners!</h1>
                    <p>Follow other curious minds and get inspired by their learning journeys. Your next "aha!" moment might come from someone else's insights!</p>
                </div>
            </div>
        );
    }
     
    if (location.pathname.startsWith('/s/') && activeTab === 'explore') {
        return (
            <div className='linko-card mt-5'>
                <div className='no-content-placeholder'>
                    <h1>🌱 Be a Pioneer!</h1>
                    <p>Looks like you're early to the party - no one in your network has shared their learning journey for this subject yet.</p>
                    <p>Why not be the first to share your insights and inspire others?</p>
                </div>
            </div>
        );
    }

    if (location.pathname.startsWith('/s/') && activeTab === 'library') {
        return (
            <div className='linko-card mt-5'>
                <div className='no-content-placeholder'>
                    <h1>📚 Start Your Journey!</h1>
                    <p>Ready to dive into this subject? Add your first learning resource and begin your adventure!</p>
                </div>
            </div>
        );
    }

    if (location.pathname.startsWith('/r/') && activeTab === 'library') {
        return (
            <div className='linko-card mt-5'>
                <div className='no-content-placeholder'>
                    <h1>✍️ Capture Your Thoughts!</h1>
                    <p>Your insights are valuable - start taking notes and let our AI help you connect the dots!</p>
                </div>
            </div>
        );
    }

    if (location.pathname.startsWith('/r/') && activeTab === 'explore') {
        return (
            <div className='linko-card mt-5'>
                <div className='no-content-placeholder'>
                    <h1>🎯 Lead the Way!</h1>
                    <p>You're the first one here! Share your learning experience and inspire others who are interested in this resource.</p>
                </div>
            </div>
        );
    }

    if (location.pathname.startsWith('/n/') && activeTab === 'notes') {
        return (
            <div className='linko-card mt-5'>
                <div className='no-content-placeholder'>
                    <h1>🔄 Connect the Dots!</h1>
                    <p>Add more notes to discover fascinating connections in your learning journey. Our AI will help you find related insights!</p>
                </div>
            </div>
        );
    }

    if (location.pathname.startsWith('/profile/')) {
        return (
            <div className='linko-card mt-5'>
                <div className='no-content-placeholder'>
                    <h1>👋 New Explorer!</h1>
                    <p>This learner hasn't shared their journey yet. Check back later to see what they're discovering!</p>
                </div>
            </div>
        );
    }
};

export default NoContentPlaceholder;