import React, { useContext } from 'react';
import { subjectPage } from '@linko/shared_utils';
import CustomLink from '../../Components/CustomLink';
import { UserContext } from '../../Context/AppContext';

const MySubjects = () => {
    const { userSubject } = useContext(UserContext);
    const myTags = userSubject?.filter(item => item.is_linked === false);
    const topSubject = userSubject?.filter(item => item.is_linked === true).sort((a, b) => b.learn_count - a.learn_count).slice(0, 20);

    return (
        <div className='your-subjects'>
            <div className='your-subjects-section hide-scrollbar'>
                {topSubject?.map((field) => (
                    <div key={field.id} className={`subject-tag ${field.learn_count_unexpand > 0 ? 'studied' : 'not-studied'}`}>
                        <CustomLink
                            to={subjectPage(field.name, !field.is_linked)}
                        >
                            {field.name}
                        </CustomLink>
                    </div>
                ))}
            </div>
            {myTags && myTags.length > 0 && (
                <div className='your-subjects-section hide-scrollbar'>
                    {myTags.map((tag) => (
                        <div key={tag.id} className='cus-label'>
                            <CustomLink
                                to={subjectPage(tag.name, !tag.is_linked)}
                            >
                                {tag.name}
                            </CustomLink>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MySubjects;
