import React, {useState, useEffect, useContext, useRef} from "react";
import { useToast } from "../ToastNotification";
import { UserContext } from "../../Context/AppContext";
import { HomePageContext } from '../../Context/HomePageContext';
import { useLocation } from 'react-router-dom';
import ResourceCardActions from "./ResourceCardActions";
import ResourceTypeIcon from "./ResourceTypeIcon";
import ResourceProgress from "./ResourceProgress";
import CardSubject from "../CardSubject";
import { postResourceToMine, convertResourceType } from "@linko/shared_utils";
import CustomLink from "../CustomLink";

const ResourceCard = ({ 
    resource , 
    onDelete, 
    onUpdate, 
    shareView, 
    currentResource,
    learnedUserData
}) => {
    const location = useLocation();
    let id, title, author, type, description, month, year, url, platform, image_link;
    if (resource && resource.resource) {
        ({
            id,
            title,
            author = "",
            type,
            description,
            month,
            year,
            link: url,
            platform,
            image_link,
        } = resource.resource);
    } else {
        ({
            id,
            title,
            author = "",
            type,
            description,
            month,
            year,
            link: url,
            platform,
            image_link,
        } = resource);
    }
    const { addResourceToLibrary } = useContext(HomePageContext);
    const resourceCardRef = useRef(null);
    const [subject, setSubject] = useState([]);
    const isBook = type === "bo";
    const imageLink = image_link && image_link.trim() !== "" 
        ? image_link.startsWith("http:")
            ? "https:" + image_link.substring(5)
            : image_link
        : null;
    const { userInfo, userResourceCount, setUserResourceCount } = useContext(UserContext);
    const [hideImage, setHideImage] = useState(false);
    const { addToast, setPersistentMessage } = useToast();
    const [imageError, setImageError] = useState(false);
    const [isNarrowCard, setIsNarrowCard] = useState(false);

    useEffect(() => {
        if (resource?.user !== null) {
            setSubject(resource.user_knowledge);
        } else {
            setSubject(resource.resource.knowledge);
        }
    }, [resource, userInfo]);

    const handleAddToLibrary = async () => {
        const response = await postResourceToMine(id);
        if (response.status === 201) {
            if (currentResource) { 
                window.location.reload(); 
                setPersistentMessage("Resource added to library");
            } else {
                addToast("Resource added to library");
            }
            addResourceToLibrary(response.data.resource);
        } else {
            window.alert("Failed to add resource to library, please try again.");
        }
        if (userResourceCount === 0) {
            setUserResourceCount(1);
        }
    }

    // Hide image when resource card is small
    useEffect(() => {
        const checkWidth = () => {
            if (resourceCardRef.current) {
                setHideImage(resourceCardRef.current.offsetWidth < 330);
                setIsNarrowCard(resourceCardRef.current.offsetWidth < 450);
            }
        };
        checkWidth();
        window.addEventListener('resize', checkWidth);

        return () => {
            window.removeEventListener('resize', checkWidth);
        };
    }, []);

    return (
        <div 
            id="resource-card" 
            ref={resourceCardRef}
            className={isNarrowCard ? 'narrow-card' : ''}
            style={{minHeight: currentResource ? '200px' : '230px', flexDirection: (currentResource || isNarrowCard) ? 'column' : 'row'}}
        >
            {!isNarrowCard && !currentResource && <ResourceTypeIcon type={type} size={24}/>}
            {(isNarrowCard || currentResource) &&
                <div className="narrow-card-header">
                    <div className="narrow-card-header-left">
                        <ResourceTypeIcon type={type} size={18}/>
                        <p>{convertResourceType(type)}</p>
                    </div>
                    {!currentResource &&
                        <ResourceCardActions
                            link={url}
                            id={id}
                            onDelete={onDelete}
                            isBook={isBook}
                            shareView={shareView}
                            handleAddToLibrary={handleAddToLibrary}
                            currentResource={currentResource}
                            isNarrowCard={isNarrowCard}
                        />
                    }
                </div>
            }
            <div className="resource-info">
                <div className="text-image-info">
                    <div className="resource-title-author">
                        <CustomLink
                            to={`/r/${id}`}
                            className={currentResource ? 'no-hover' : 'resource-title'}
                        >
                            {title}
                        </CustomLink>
                        <div className="author" style={{columnGap:'0px'}}>
                            {author && <span>{author}</span>}
                            {author && year && <span>, {year}</span>}
                            {author && year && month && <span>.{month.toString().padStart(2, '0')}</span>}
                            {platform && platform !== 'null' && <span> , {platform} </span> }
                        </div>
                        {!shareView && <ResourceProgress id={id} learnHistory={resource} onUpdate={onUpdate}/>}
                    </div>
                    {imageLink !== null && !hideImage && !imageError && (
                        <div className="resource-image">
                            <img
                                src={imageLink}
                                alt={title}
                                onError={() => setImageError(true)}
                            />
                        </div>
                    )}
                </div>
                <CardSubject
                    subject={subject}
                    setSubject={setSubject}
                    shareView={shareView}
                    resource={resource}
                    resourceId={id}
                    onUpdate={onUpdate}
                />
                {currentResource && 
                    <ResourceCardActions
                        link={url}
                        id={id}
                        onDelete={onDelete} 
                        isBook={isBook}
                        shareView={shareView}
                        handleAddToLibrary={handleAddToLibrary}
                        currentResource={currentResource}
                        learnedUserData={learnedUserData}
                    />
                }
            </div>
            {!currentResource && !isNarrowCard &&
                <ResourceCardActions
                    link={url}
                    id={id}
                    onDelete={onDelete}
                    isBook={isBook}
                    shareView={shareView}
                    handleAddToLibrary={handleAddToLibrary}
                    currentResource={currentResource}
                    isNarrowCard={isNarrowCard}
                />
            }
        </div>
    );
};

export default ResourceCard;
