import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { HomePageContext } from '../../Context/HomePageContext.jsx';
import { fetchMyFeed, fetchFollowingFeed } from '@linko/shared_utils';
import TheTabs from './TheTabs.jsx';

const TabDataWrapper = ({ subjectId = null, resourceId = null, library, setLibrary, ...props }) => {
    const location = useLocation();
    const homePage = location.pathname.startsWith('/my_linko');
    const { 
        homePageState,
        setHomePageState, 
        updateLibraryArray,
        updateExploreArray,
        setIsFetchingMoreLibraryHomePage,
        setIsFetchingMoreExploreHomePage
    } = useContext(HomePageContext);
    
    // Explore state
    const [explore, setExplore] = useState([]);
    const [exploreOffset, setExploreOffset] = useState(0);
    const [isFetchingMoreExplore, setIsFetchingMoreExplore] = useState(true);
    const [hasMoreExplore, setHasMoreExplore] = useState(true);
    
    // Library pagination state
    const [libraryOffset, setLibraryOffset] = useState(0);
    const [isFetchingMoreLibrary, setIsFetchingMoreLibrary] = useState(true);
    const [hasMoreLibrary, setHasMoreLibrary] = useState(true);

    const limit = 10;
    
    const handleSetLibrary = (newLibrary) => {
        if (homePage) {
            updateLibraryArray(newLibrary);
        } else {
            setLibrary(newLibrary);
        }
    };

    const handleSetExplore = (newExplore) => {
        if (homePage) {
            updateExploreArray(newExplore);
        } else {
            setExplore(newExplore);
        }
    };

    const handleSetIsFetchingMoreLibrary = (value) => {
        if (homePage) {
            setIsFetchingMoreLibraryHomePage(value);
        } else {
            setIsFetchingMoreLibrary(value);
        }
    };

    const handleSetIsFetchingMoreExplore = (value) => {
        if (homePage) {
            setIsFetchingMoreExploreHomePage(value);
        } else {
            setIsFetchingMoreExplore(value);
        }
    };

    // Initial data fetch for non-homepage
    useEffect(() => {
        if (homePage) return;

        setExplore([]);
        setLibrary([]);
        setExploreOffset(0);
        setLibraryOffset(0);
        setIsFetchingMoreExplore(true);
        setIsFetchingMoreLibrary(true);
        setHasMoreExplore(true);
        setHasMoreLibrary(true);

        const fetchData = async () => {
            if (subjectId || resourceId) {
                // Fetch library data only if empty
                const libraryResponse = await fetchMyFeed(
                    limit, 0, subjectId, resourceId,
                    null, null, null, null, null,
                );
                if (Array.isArray(libraryResponse.data.results)) {
                    setLibrary(libraryResponse.data.results);
                    setLibraryOffset(limit);
                    setHasMoreLibrary(libraryResponse.data.results.length === limit);
                    setIsFetchingMoreLibrary(false);
                }
            
                // Fetch explore data
                const exploreResponse = await fetchFollowingFeed(
                    limit, 0, subjectId, resourceId,
                    null, null, null, null
                );
                if (Array.isArray(exploreResponse.data.results)) {
                    setExplore(exploreResponse.data.results);
                    setExploreOffset(limit);
                    setHasMoreExplore(exploreResponse.data.results.length === limit);
                    setIsFetchingMoreExplore(false);
                }
            }
        };
        
        fetchData();
    }, [subjectId, resourceId, homePage]);

    // Handle infinite scroll for library
    useEffect(() => {
        if (homePage || !isFetchingMoreLibrary || libraryOffset === 0) return;

        const fetchMoreLibrary = async () => {
            const response = await fetchMyFeed(
                limit, libraryOffset, subjectId, resourceId,
                null, null, null, null, null,
            );
            if (Array.isArray(response.data.results)) {
                setLibrary(prev => [...prev, ...response.data.results]);
                setLibraryOffset(prev => prev + limit);
                setHasMoreLibrary(response.data.results.length === limit);
                setIsFetchingMoreLibrary(false);
            }
        };
        fetchMoreLibrary();
    }, [isFetchingMoreLibrary]);

    // Handle infinite scroll for explore
    useEffect(() => {
        if (homePage || !isFetchingMoreExplore || exploreOffset === 0) return;

        const fetchMoreExplore = async () => {
            const response = await fetchFollowingFeed(
                limit, exploreOffset, subjectId, resourceId,
                null, null, null, null
            );
            if (Array.isArray(response.data.results)) {
                setExplore(prev => [...prev, ...response.data.results]);
                setExploreOffset(prev => prev + limit);
                setHasMoreExplore(response.data.results.length === limit);
                setIsFetchingMoreExplore(false);
            }
        };
        fetchMoreExplore();
    }, [isFetchingMoreExplore]);

    return (
        <TheTabs
            {...props}
            subjectId={subjectId}
            resourceId={resourceId}
            library={homePage ? homePageState.library : library}
            setLibrary={handleSetLibrary}
            explore={homePage ? homePageState.explore : explore}
            setExplore={handleSetExplore}
            isFetchingMoreLibrary={homePage ? homePageState.isFetchingMoreLibrary : isFetchingMoreLibrary}
            isFetchingMoreExplore={homePage ? homePageState.isFetchingMoreExplore : isFetchingMoreExplore}
            hasMoreLibrary={homePage ? homePageState.hasMoreLibrary : hasMoreLibrary}
            hasMoreExplore={homePage ? homePageState.hasMoreExplore : hasMoreExplore}
            setIsFetchingMoreLibrary={handleSetIsFetchingMoreLibrary}
            setIsFetchingMoreExplore={handleSetIsFetchingMoreExplore}
        />
    );
};

export default TabDataWrapper;