import React, { useState, useEffect } from 'react';
import RichEditor from './RichEditor';
import { CgCompressRight } from "react-icons/cg";
import { FaLock } from "react-icons/fa";
import { FaUnlock } from "react-icons/fa";
import Tippy from '@tippyjs/react';

const FocusMode = ({
    handleSubmit,
    title,
    handleTitleChange,
    handleEnterKey,
    handleTitlePaste,
    setIsFullScreen,
    isPrivate,
    handlePrivateChange,
    handleCancel,
    isLoading,
    note,
    handleEditorChange,
    editorContent,
}) => {
    const [isTouchDevice, setIsTouchDevice] = useState(false);

    useEffect(() => {
        const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        setIsTouchDevice(isTouch);
    }, []);

    return (
        <div className="focus-mode">
            <div className='focus-mode-header'>
                <div className="linko-navbar-brand">
                    <h1 className='logo'>
                    Linko
                    </h1>
                    <p id="beta">
                    Beta
                    </p>
                    <p className="slogan" style={{whiteSpace:'nowrap'}}>
                    Learning as a lifestyle
                    </p>
                </div>
                <div className='cancel-or-submit'>
                    <div className='private-checkbox'>
                        <label 
                            title={`${isPrivate ? 'Make it public' : 'Set as private'}`}
                            htmlFor='private-checkbox' 
                            onClick={handlePrivateChange} 
                            className='linko-button linko-button--cancel'
                            style={{marginRight:'5px'}}
                        >
                            {isPrivate ? 
                                <>
                                    <FaLock size={14} style={{cursor: 'pointer'}}/>
                                    <span className='hide-small'>Private</span>
                                </>
                            :
                                <>
                                    <FaUnlock size={14} style={{cursor: 'pointer'}}/>
                                    <span className='hide-small'>Public</span>
                                </>
                            }
                        </label>
                    </div>
                    <button onClick={handleCancel} className='linko-button linko-button--cancel' style={{marginRight:'5px'}}>Cancel</button>
                    <button onClick={(event) => {handleSubmit(event); setIsFullScreen(false);}} className='linko-button linko-button--primary' disabled={isLoading}>Save</button>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className='add-note-card-header'>
                    <textarea 
                    value={title} 
                    onChange={handleTitleChange}
                    onKeyDown={handleEnterKey} 
                    onPaste={handleTitlePaste}
                    placeholder='Title' 
                    className='add-note-title'
                    rows={1}  
                    />
                    <Tippy 
                        content="Quit focus mode"
                        placement="bottom"
                        arrow={true}
                        theme='light-border'
                        hideOnClick={true} 
                        delay={[0, 0]} 
                        touch={false}
                        disabled={isTouchDevice}
                    >
                    <div>
                        <CgCompressRight 
                        title='Exit focus mode'
                        onClick={() => setIsFullScreen(false)}
                        />
                    </div>
                    </Tippy>
                </div>  
                <RichEditor 
                    key={note?.id}
                    value={editorContent}
                    onChange={handleEditorChange} 
                    placeholder='Write your note...'
                />
            </form>
        </div>
    );
};

export default FocusMode;
