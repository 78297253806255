import React, { useState, useEffect, useContext } from 'react';
import { subjectPage, fetchUserSubject } from '@linko/shared_utils';
import { AuthContext, UserContext } from '../../Context/AppContext';
import CustomLink from '../../Components/CustomLink';

const UserSubjects = ({userName, userId, isCurrentUser}) => {
  const [subjects, setSubjects] = useState([]);
  const [overlap, setOverlap] = useState([]);
  const { userSubject } = useContext(UserContext);
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    setOverlap([]);
    setSubjects([]);
    const loadData = async () => {
      try {
        const response = await fetchUserSubject(userId);
        setSubjects(response.userSubject
          .sort((a, b) => b.learn_count - a.learn_count)
          .slice(0, 10) 
        );
      } catch (error) {
        console.error('Error fetching user subjects:', error);
      }
    };
    if (!isCurrentUser) { 
      loadData();
    } else {
      setSubjects(userSubject.sort((a, b) => b.learn_count - a.learn_count).slice(0, 10));
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const overlapSubjects = subjects
          .filter(subject => userSubject.some(userSub => userSub.id === subject.id))
          .map(subject => ({
              ...subject,
              userLearnCount: userSubject.find(userSub => userSub.id === subject.id).learn_count
          }))
          .sort((a, b) => b.userLearnCount - a.userLearnCount)
          .slice(0, 10);
      setOverlap(overlapSubjects);
    }
  }, [subjects, userSubject, isAuthenticated]);

  if (subjects.length === 0) {
    return (
      <div className='subject-rel'>
        <div className='subject-rel-title'>Fields</div>
        <div className='subject-rel-list'>
          <p style={{fontStyle: 'italic', color:'#666666'}}>This user hasn't added anything yet.</p>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='subject-rel'>
          <div className='subject-rel-title'>Fields</div>
        {subjects && subjects.length > 0 && 
          <div className='subject-rel-list'>
              {subjects.map((field, index) => (
                  <div key={index} className={`subject-tag ${field.learn_count > 0 ? 'studied' : 'not-studied'}`}>
                      <CustomLink
                          to={subjectPage(field.name, false)}
                      >
                          {field.name}
                      </CustomLink>
                  </div>
              ))}
          </div>
        }
      </div>
      <div className='subject-rel'>
          <div className='subject-rel-title'>Shared Interests</div>
          {isAuthenticated && overlap && overlap.length > 0 && 
          <div className='subject-rel-list'>
                  {overlap.map((field, index) => (
                      <div key={index} className={`subject-tag ${field.learn_count > 0 ? 'studied' : 'not-studied'}`}>
                          <CustomLink
                              to={subjectPage(field.name, false)}
                          >
                              {field.name}
                          </CustomLink>
                  </div>
              ))} 
          </div>
        }
      </div>
    </>
  );
};

export default UserSubjects;
