import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import BackButton from '../../Components/BackButton';
import Loader from '../../Components/Loader';
import ResourceCard from '../../Components/ResourceCard/ResourceCard';
import AddNoteButton from '../../Components/NoteCard/AddNoteButton';
import TabDataWrapper from '../../Components/ContentTabs/TabDataWrapper';
import ResourcePageRightTabs from './Tabs';

import { fetchSingleResource, useWindowSize, convertResourceType, fetchBestUnlearned } from '@linko/shared_utils';

// Custom hook to get window size

function ResourcePage() {
  const { id } = useParams();
  const resourceId = id;
  const location = useLocation();
  const { safeNavigate } = useContext(NavigationPromptContext);
  const openAddNote = location.state?.openAddNote;
  const [resource, setResource] = useState(null);
  const [resourceType, setResourceType] = useState(null);
  const [showTextArea, setShowTextArea] = useState(false);
  const [learnedUserData, setLearnedUserData] = useState(null);
  const [shareView, setShareView] = useState(null);
  const [articleContent, setArticleContent] = useState(null);
  const [embeddable, setEmbeddable] = useState(null);
  const [authorInfo, setAuthorInfo] = useState(null);
  const { width } = useWindowSize(); 
  const [recommendations, setRecommendations] = useState([]);
  const [library, setLibrary] = useState([]);

  // Fetch this resource info
  const fetchResource = async () => {
    const response = await fetchSingleResource(resourceId);
    setResource(response.resource);
    setResourceType(convertResourceType(response.resource.resource.type));
    setLearnedUserData(response.learnedUsers);
    setShareView(response.resource.user === null ? true : false);
    setArticleContent(response.articleContent);
    setEmbeddable(response.bookEmbeddable);
    setAuthorInfo(response.authorInfo);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setResource(null);
        await fetchResource();
      } catch (error) {
        console.error('Error fetching resource data:', error);
      }
    };
    
    fetchData();
  }, [resourceId]);

  useEffect(() => {
    if (resource) {
      fetchRecommendations();
    }
  }, [resource]);

  const fetchRecommendations = async () => {
    if (!resource) {
      return;
    }
    let subjectIds = [];
    if (resource.user_knowledge && resource.user_knowledge.length > 0) {
      subjectIds = resource.user_knowledge.map(subject => subject.id);
    } else if (resource.resource.knowledge && resource.resource.knowledge.length > 0) {
      subjectIds = resource.resource.knowledge.map(subject => subject.id);
    } else {
      return [];
    }

    try {
      const response = await fetchBestUnlearned(subjectIds);
      if (response.data.detail === "No unlearned resources found for the given subject.") {
        setRecommendations([]);
      } else {
        setRecommendations(response.data);   
      }
    } catch (error) {
      console.error('Failed to fetch recommendations:', error);
      setRecommendations([]);
    }
  }


  const onDelete = (id) => {
    safeNavigate('/my_linko');
  }

  if (!resource) return (
    <div className='page-loading'>
      <Loader />
    </div>
  );
  
  return (
    <div className='resource-page-container'>
      <div className='resource-page-left'>
        <BackButton />
        <div className='resource-page-resource' >
          <ResourceCard
            resource={resource}
            onDelete={onDelete}
            currentResource={true}
            shareView={shareView}
            learnedUserData={learnedUserData}
          />
          {width < 1089 && 
            <ResourcePageRightTabs 
              resource={resource.resource} 
              articleContent={articleContent} 
              embeddable={embeddable}
              authorInfo={authorInfo}
              recommendations={recommendations}
            />
          }
        </div>
        <AddNoteButton 
          subjectId={null}
          noteHint={`Take a note on this ${resourceType}.`}
          resource={resource}
          showTextArea={showTextArea}
          setShowTextArea={setShowTextArea}
          library={library}
          setLibrary={setLibrary}
        />
        <TabDataWrapper
          resourceId={resourceId} 
          resourceType={resourceType}
          library={library}
          setLibrary={setLibrary}
        />
      </div>
      {width >= 1089 && 
        <ResourcePageRightTabs 
          resource={resource.resource} 
          articleContent={articleContent}
          embeddable={embeddable}
          authorInfo={authorInfo}
          recommendations={recommendations}
        />
      }
    </div> 
  );
}

export default ResourcePage;
