import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../Context/AppContext';
import { useLocation } from 'react-router-dom';
import { HomePageContext } from '../../Context/HomePageContext';
// Components
import SelectNoteView from './SelectNoteView';
import TabLibrary from './TabLibrary';
import TabExplore from './TabExplore';
import TabRevisit from './TabRevisit';
import NoContentPlaceholder from './NoContentPlaceholder';
// Functions
import { handleScrollEvent, setupScrollListeners } from './handleScroll';
import { setupTabs } from './tabManagement';

const TheTabs = ({
        subjectId,
        publicField,
        resourceId, 
        resourceType,
        library,
        setLibrary,
        explore,
        setExplore,
        isFetchingMoreLibrary,
        isFetchingMoreExplore,
        hasMoreLibrary,
        hasMoreExplore,
        setIsFetchingMoreLibrary,
        setIsFetchingMoreExplore
    }) => {

    const location = useLocation();
    const { newFeedCount, setNewFeedCount, fetchNotifCount } = useContext(UserContext);
    const homePage = location.pathname.startsWith('/my_linko');
    const resourcePage = location.pathname.startsWith('/r/');
    const { homePageState, setHomePageState, isShufflingSubject } = useContext(HomePageContext);
    const { userNoteCount, userResourceCount } = useContext(UserContext);

    // Tab state
    const [activeTab, setActiveTab] = useState(() => {
        if (homePage && homePageState.activeTab) {
            return homePageState.activeTab;
        }
        return 'library';
    });
    const [activeSubTab, setActiveSubTab] = useState(() => {
        if (homePage && homePageState.activeSubTab) {
            return homePageState.activeSubTab;
        }
        return 'ignite';
    });
    const [tabNames, setTabNames] = useState({ first: null, second: null });

    // Setup effects
    useEffect(() => {
        setupTabs(location, setTabNames);
        fetchNotifCount();
    }, [location.pathname]);

    // Update tab if home page state changes
    useEffect(() => {
        if (homePage) {
            setActiveTab(homePageState.activeTab);
            setActiveSubTab(homePageState.activeSubTab);
        }
    }, [homePageState]);

    // Scroll position management
    useEffect(() => {
        if (homePage) {
            requestAnimationFrame(() => {
                const scrollPosition = homePageState.scrollPositions[activeTab] || 0;
                window.scrollTo(0, scrollPosition);
            });
        } else {
            window.scrollTo(0, 0);
        }
    }, [activeTab, homePage]);

    useEffect(() => {
        const cleanup = setupScrollListeners(
            handleScrollEvent,
            activeTab,
            null, // setIsFetchingMoreNotes (not used here)
            null, // setIsFetchingMoreResources (not used here)
            setIsFetchingMoreExplore,
            setIsFetchingMoreLibrary,
            false, // hasMoreNotes
            false, // hasMoreResources
            hasMoreExplore,
            hasMoreLibrary
        );
    
        return () => {
            cleanup();
        };
    }, [
        activeTab,
        hasMoreLibrary,
        hasMoreExplore,
        setIsFetchingMoreLibrary,
        setIsFetchingMoreExplore
    ]);

    useEffect(() => {
        let lastScrollTop = 0;
        
        const handleNavbarScroll = (currentScroll) => {
            const navbar = document.querySelector('.tabs-wrapper');
            if (navbar) {
                if (currentScroll > lastScrollTop) {
                    navbar.style.top = '0px'; 
                } else {
                    navbar.style.top = '70px';
                }
                lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
            }
        };

        const handleScroll = () => {
            const currentScroll = window.scrollY || document.documentElement.scrollTop;
            
            // Handle navbar position
            handleNavbarScroll(currentScroll);
            
            // Handle scroll position saving for homepage
            if (homePage) {
                const actualTab = activeTab === 'revisit' || activeTab === 'library' 
                    ? (activeSubTab === 'ignite' ? 'revisit' : 'library')
                    : activeTab;
                    
                setHomePageState(prev => ({
                    ...prev,
                    scrollPositions: {
                        ...prev.scrollPositions,
                        [actualTab]: currentScroll
                    }
                }));
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [homePage, activeTab, activeSubTab]);

    // Save state to context for homepage
    useEffect(() => {
        if (homePage) {
            setHomePageState(prev => ({
                ...prev,
                activeTab,
                activeSubTab,
                scrollPositions: {
                    ...prev.scrollPositions,
                    [activeTab]: window.scrollY
                }
            }));
        }
    }, [activeTab, activeSubTab, homePage]);

    return (
        <div className='the-tabs'>
            <div className="tabs-wrapper">
                <div className='tabs'>
                    <button
                        className={`tab-button ${(activeTab === 'library' || activeTab === 'revisit') ? 'active' : ''}`}
                        onClick={() => homePage ? (activeSubTab === 'ignite' ? setActiveTab('revisit') : setActiveTab('library'))
                            : setActiveTab('library')}
                    >
                        {tabNames.first}
                    </button>
                    {(publicField || resourcePage || homePage) && 
                        <button
                            className={`tab-button ${activeTab === 'explore' ? 'active' : ''}`}
                            onClick={() => {
                                setActiveTab('explore');
                                setNewFeedCount(0);
                            }}
                        >
                            <div style={{ position: 'relative' }}>
                                {tabNames.second}
                                {homePage && newFeedCount > 0 && (
                                    <div className='new-feed-count' />
                                )}
                            </div>
                        </button>
                    }
                </div>

                {(activeTab ==='library' || activeTab === 'revisit')&& homePage &&
                    <SelectNoteView
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        setActiveSubTab={setActiveSubTab}
                    />
                }
            </div>
            <div className="tab-content">
            {activeTab === 'revisit' ? (
                <div id='tab-revisit' 
                    className={`tab ${activeTab === 'revisit' ? 'active' : 'hidden'}`} >
                    {userNoteCount + userResourceCount === 0 && !isFetchingMoreLibrary ? 
                        <NoContentPlaceholder activeTab={'revisit'}/>
                    :
                        <TabRevisit />
                    }
                </div>
            ) : activeTab === 'library' ? (
                <div id='tab-library' 
                    className={`tab ${activeTab === 'library' ? 'active' : 'hidden'}`}
                    style={{ display: activeTab === 'library' ? 'block' : 'none' }}>
                    <TabLibrary
                        library={library}
                        setLibrary={setLibrary}
                        isFetchingMoreLibrary={isFetchingMoreLibrary}
                        hasMoreLibrary={hasMoreLibrary}
                    />
                </div>
            ) : activeTab === 'explore' ? (
                <div id='tab-explore' 
                    className={`tab ${activeTab === 'explore' ? 'active' : 'hidden'}`} 
                    style={{ display: activeTab === 'explore' ? 'block' : 'none' }}>
                <TabExplore
                    explore={explore}
                    setExplore={setExplore}
                    isFetchingMoreExplore={isFetchingMoreExplore}
                    hasMoreExplore={hasMoreExplore}
                    type={resourceType ? resourceType : 'resource'}
                    resourceId={resourceId}
                />
                </div>
            ) : null}
            </div>
        </div>
    );
};

export default TheTabs;
