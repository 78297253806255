import React, { useState , useEffect , useRef, useCallback, useContext } from 'react';
import { UserContext } from '../Context/AppContext';
import { HomePageContext } from '../Context/HomePageContext';
import { useLocation } from 'react-router-dom';
import { TiDelete } from "react-icons/ti";
import { GoPlusCircle } from "react-icons/go";
import { RxCross2 } from "react-icons/rx";
import Loader from './Loader';
import { useToast } from './ToastNotification';
import { FaRegQuestionCircle } from "react-icons/fa";
import Tippy from '@tippyjs/react';
import CustomLink from './CustomLink';
import { searchKnowledge, putNote, putMyResource, subjectPage, fetchSuggestedSubjects } from '@linko/shared_utils';

const CardSubject = ({
    subject, 
    setSubject, 
    shareView, 
    resource, 
    resourceId,
    onUpdate, 
    noteId, 
    updateSingleNote,
    }) => {

    const location = useLocation();

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [hasExactMatch, setHasExactMatch] = useState(false);
    const { addToast } = useToast();
    const textareaRef = useRef(null);
    const timeoutId = useRef();;
    const [suggested, setSuggested] = useState([]);

    const { fetchUserSubjectData } = useContext(UserContext);
    const { updateSingleNoteInLibrary, updateSingleResourceInLibrary } = useContext(HomePageContext);

    useEffect(() => {
        if (isSearching && searchTerm === '') {
            const fetchSuggested = async () => {
                try {
                    const response = await fetchSuggestedSubjects(resourceId, noteId);
                    const currentSubjectIds = new Set(subject?.map(s => s.id) || []);
                    const sortedResponse = response
                        .filter(item => !currentSubjectIds.has(item.id))
                        .sort((a, b) => {
                            if (a.is_linked === b.is_linked) return 0;
                            return a.is_linked ? 1 : -1;
                        });
                    setSuggested(sortedResponse);
                } catch (error) {
                    console.error('Error fetching suggested subjects:', error);
                }
            };
            fetchSuggested();
        }
    }, [isSearching, searchTerm, resourceId, noteId]);

    useEffect(() => { 
        const fetchSubject = async () => {
            if (searchTerm !== '') {
                timeoutId.current && clearTimeout(timeoutId.current);
                timeoutId.current = setTimeout(async () => {
                    try {
                        const response = await searchKnowledge(searchTerm);
                        setSearchResults(response);
                        const exactMatch = response.some(item => item.name && item.name.toLowerCase().replace(/\s/g, '') === searchTerm.toLowerCase().replace(/\s/g, ''));
                        setHasExactMatch(exactMatch);
                    } catch (error) {
                        console.error('Error fetching subject:', error.response ? error.response.data : error.message);
                    }
                }, 1000);
            } else {
                setSearchResults([]);
                setHasExactMatch(false);
            }
        };
        fetchSubject();
    }, [searchTerm]);  

    useEffect(() => {
        if (isSearching && textareaRef.current) {
          textareaRef.current.focus();
        }
      }, [isSearching]);

    const handleDeleteSubject = async (id) => {
        setSubject(prevSubject => {
            const newSubject = prevSubject.filter(k => k.id !== id);
            updateSubject(newSubject);
            return newSubject;
        });
    };
    
    const handleResultClick = async (subject) => {
        const subjectArray = Array.isArray(subject) ? subject : [];

        if (subjectArray.some(k => k.id === subject.id)) {
            alert('This subject is already added');
            return;
        }
        setSubject(prevSubject => {
            const updatedSubject = [...prevSubject, subject];
            updateSubject(updatedSubject, null);
            return updatedSubject;
        });
        setSearchTerm('');
        setSearchResults([]);
        setIsSearching(false);
    };

    const handleCustomLabelClick = useCallback(async () => {
        const customTagString = String(searchTerm).trim();
        const newSubject = { id: null, name: customTagString, is_linked: false };
        setIsSearching(false);
        await updateSubject([...subject], customTagString);
        await new Promise(resolve => setTimeout(resolve, 1000));
        setSearchTerm('');
        setSearchResults([]);
        addToast('Custom topic created.', { appearance: 'success' });
    }, [searchTerm, subject]);

    const updateSubject = async (subject, customTag) => {
        if (customTag) {
            const newSubject = { id: null, name: customTag, is_linked: false };
            const updatedSubject = [...(subject || []), newSubject];
            
            try {

                if (noteId) {
                    await putNote(noteId, null, null, [customTag], null);
                    updateSingleNoteInLibrary(noteId);
                } else if (resourceId) {
                    await putMyResource(resourceId, null, null, null, [customTag]);
                    updateSingleResourceInLibrary(resourceId);
                }
                fetchUserSubjectData();
                setSubject(updatedSubject);
                
                if (onUpdate && !location.pathname.startsWith('/my_linko')) {
                    onUpdate({ ...resource, user_knowledge: updatedSubject });
                }
                if (updateSingleNote && noteId && !location.pathname.startsWith('/my_linko')) {
                    updateSingleNote(noteId);
                }
            } catch (error) {
                console.error('Error updating subject:', error);
            }
            return;
        }

        const subjectIds = subject?.map(k => k.id).filter(id => id !== null);
        
        try {
            if (noteId) {
                await putNote(noteId, null, subjectIds, null, null);
                updateSingleNoteInLibrary(noteId);
            } else if (resourceId) {
                await putMyResource(resourceId, null, null, subjectIds, null);
                updateSingleResourceInLibrary(resourceId);
            }
            fetchUserSubjectData();
            setSubject(subject);
            
            if (onUpdate && !location.pathname.startsWith('/my_linko')) {
                onUpdate({ ...resource, user_knowledge: subject });
            }
            if (updateSingleNote && noteId && !location.pathname.startsWith('/my_linko')) {
                updateSingleNote(noteId);
            }
        } catch (error) {
            console.error('Error updating subject:', error);
        }
    };

  return (
    <div className="note-card-subjects">
        <div className="subjects" style={{marginBottom: noteId ? '16px' : '0px' }}>
            {subject && 
                [...subject]
                .filter(k => !shareView || (shareView && k.is_linked))
                .sort((a, b) => a.is_linked === b.is_linked ? 0 : a.is_linked ? 1 : -1)
                .map((k) => (
                    <div className={`subject-tag ${k.is_linked ? 'studied' : 'cus-label'}`} key={k.id}>
                            <CustomLink to={subjectPage(k.name, !k.is_linked)}>
                                {k.name}
                            </CustomLink>
                            {!shareView && 
                                <TiDelete 
                                    className='delete-subject-icon' 
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteSubject(k.id);
                                    }}
                                />
                            }
                    </div>
                ))
            }
            {!shareView && 
            <>
            {!isSearching ? (
                <div className='add-note-subject'>
                    <GoPlusCircle 
                        className='add-subject-icon' 
                        style={{marginLeft:'0px'}}
                        onClick={() => setIsSearching(true)}
                    />
                </div>
                ) : (
                <div className='searching'>
                        <RxCross2 
                            className='minus-icon' 
                            onClick={() => setIsSearching(false)}
                        />
                        <textarea 
                            className='add-note-subject-textarea' 
                            value={searchTerm}
                            ref={textareaRef}
                            placeholder="Search" 
                            onChange={e => {
                                setSearchTerm(e.target.value);
                                setSearchResults([]);
                            }}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </div>
            )}
            {isSearching && (
                <div className='subject-search-result'>
                    {searchTerm === '' ? (
                        suggested.length > 0 ? (
                            <div className='subjects'>
                                {suggested.map((result) => (
                                    <div className={`subject-tag ${result.is_linked ? 'studied' : 'cus-label'}`} key={result.id} onClick={() => handleResultClick(result)}>
                                        <p>{result.name}</p>
                                    </div>
                                ))}
                            </div>
                        ) : <Loader size={20}/>
                    ) 
                    : 
                    (
                        searchResults?.length === 0 ? <Loader size={20}/> :
                        <>
                            {!hasExactMatch &&(
                                <div className='suggesting-cus-label'>
                                    <p>Create a custom tag</p>
                                    <Tippy 
                                        interactive={true}
                                        content={(
                                            <p>Your custom tags are only accessable and visible to you.</p>
                                        )}
                                        placement="right"
                                        arrow={true}
                                        theme='light-border'
                                        delay={[0, 0]} 
                                    >
                                        <div style={{display:'flex', alignItems:'center'}}>
                                            <FaRegQuestionCircle/>
                                        </div>
                                    </Tippy>
                                    <p>: </p>
                                    <div className='cus-label'>
                                        <p onClick={handleCustomLabelClick}>{searchTerm}</p>
                                    </div>
                                </div>
                            )}
                            <div className='subjects'>
                                {searchResults?.map((result) => (
                                    <div className={`subject-tag ${result.is_linked ? 'studied' : 'cus-label'}`} key={result.id} onClick={() => handleResultClick(result)}>
                                        <p>{result.name}</p>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            )}
            </>
            }
        </div>
    </div>
)};

export default CardSubject;
