export const DateFormatter = (date) => {
    if (!date) {
        return '';
    }
    const currentDate = new Date();
    const inputDate = new Date(date);

    const timeDiff = currentDate.getTime() - inputDate.getTime();
    const minutesDiff = Math.floor(timeDiff / (1000 * 60));
    const hoursDiff = Math.floor(timeDiff / (1000 * 3600));
    const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));

    // Handle recent times first
    if (minutesDiff < 1) {
        return 'just now';
    } else if (minutesDiff < 60) {
        return `${minutesDiff} ${minutesDiff === 1 ? 'minute' : 'minutes'} ago`;
    } else if (hoursDiff < 24) {
        return `${hoursDiff} ${hoursDiff === 1 ? 'hour' : 'hours'} ago`;
    }

    // For dates older than 2 years, show the actual date
    if (daysDiff >= 6) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
                      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = months[inputDate.getMonth()];
        const day = inputDate.getDate().toString().padStart(2, '0');
        return `on ${month} ${day}`;
    }

        // Rest of the date formatting logic
        if (daysDiff === 0) {
            return 'today';
        } else if (daysDiff === 1) {
            return 'yesterday';
        } else if (daysDiff < 6) {
            return `${daysDiff} days ago`;
        } 
    };