import React, { useState, useContext } from 'react';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { useToast } from '../ToastNotification';
import Loader from '../Loader';
import { IoClose } from "react-icons/io5";
import { putResource, deleteResource, fetchSingleResource } from '@linko/shared_utils';


const ResourceManualInput = ({onClose, resourceId, setSelectedResource}) => {
    const [title, setTitle] = useState('');
    const [author, setAuthor] = useState('');
    const [type, setType] = useState('');
    const [year, setYear] = useState(null);
    const [month, setMonth] = useState(null);
    const [selectingMonth, setSelectingMonth] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    const { safeNavigate } = useContext(NavigationPromptContext);
    const { addToast, setPersistentMessage } = useToast();

    const monthsOptions = [
        { label: 'Jan', value: '1' },
        { label: 'Feb', value: '2' },
        { label: 'Mar', value: '3' },
        { label: 'Apr', value: '4' },
        { label: 'May', value: '5' },
        { label: 'Jun', value: '6' },
        { label: 'Jul', value: '7' },
        { label: 'Aug', value: '8' },
        { label: 'Sep', value: '9' },
        { label: 'Oct', value: '10' },
        { label: 'Nov', value: '11' },
        { label: 'Dec', value: '12' },
    ];
    

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        putResource(resourceId, title, author, type, year, month)
        .then(async response => {
            if (response.status === 200) {
                setIsLoading(false);
                onClose();
                const newResourceResponse = await fetchSingleResource(resourceId);
                if (setSelectedResource) {
                    setSelectedResource(newResourceResponse.data.resource);
                } else {
                safeNavigate(`/r/${resourceId}`);
                }
                setPersistentMessage('Link added successfully');
            } else {
                setIsLoading(false);
                addToast('Failed to add link, please try again');
            }
        })
    };


    const handleLeft = () => {
        deleteResource(resourceId);
        onClose();
    }

    return (
        <>
        <div className='add-modal manual-input-modal'>
            {isLoading ? <Loader /> : 
            <>
            <div className='add-modal-header' style={{marginBottom:'10px'}}>
                <h2 style={{marginBottom:'8px', fontSize:'16px', color:'#666666'}}>
                    This domain is currently not supported. Please add the resource manually.
                </h2>
                <button className="close-button" onClick={() => handleLeft()}>
                    <IoClose />
                </button>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="manual-input-group">
                    <label>Title <span className="required">*</span></label>
                    <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required placeholder='Title of resource'/>
                </div>
                <div className="manual-input-group">
                    <label>Author</label>
                    <input type="text" value={author} onChange={(e) => setAuthor(e.target.value)} placeholder='Author of resource'/>
                </div>
                <div className="manual-input-group">
                    <label>Type</label>
                    <select value={type} onChange={(e) => setType(e.target.value)}>
                        <option value="" disabled selected>Select resource type</option>
                        <option value="ar">Article</option>
                        <option value="vi">Video</option>
                        <option value="po">Podcast</option>
                        <option value="oc">Online Course</option>
                        <option value="or">Link</option>
                    </select>
                </div>
                <div className="manual-input-group" style={{height:'fit-content', flexDirection:'row', columnGap:'10px'}}>
                    <div className="manual-input-group" style={{marginRight:'15px'}}>
                        <label>Year</label>
                        <select value={year} onChange={(e) => setYear(e.target.value)}>
                            <option value="">-- Select year --</option>
                            {[...Array(new Date().getFullYear() - 1899).keys()].map((_, index) => {
                                const year = new Date().getFullYear() - index;
                                return <option key={year} value={year}>{year}</option>;
                            })}
                        </select>
                    </div>
                    <div className="manual-input-group">
                        <label>Month</label>
                        <select value={month} onChange={(e) => setMonth(e.target.value)} >
                            <option value="">-- Select month --</option>
                            {monthsOptions.map((monthOption) => (
                                <option key={monthOption.value} value={monthOption.value}>{monthOption.label}</option>
                            ))}
                        </select>                        
                    </div>
                </div>
                <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                    <button type='button' onClick={handleLeft} style={{border:'none', backgroundColor:'transparent', color:'#636363', fontSize:'16px', marginRight:'20px', fontWeight:'500'}} >Cancel</button>
                    <button className="add-with-link" type="submit">Submit</button>
                </div>
            </form>
            </>}
        </div>
        </>
    );
};

export default ResourceManualInput;