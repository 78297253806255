import React, { useState, useContext, useEffect } from 'react';
import { fetchUserFollow, sendFollowRequest, profilePage } from '@linko/shared_utils';
import { AuthContext, FollowContext, UserContext } from '../../Context/AppContext';
import { useToast } from '../../Components/ToastNotification'
import UserModal from './UserModal';
import CustomAvatar from '../../Components/CustomAvatar';
import CustomLink from '../../Components/CustomLink';

const UserFollows = ({ userId, userName, isCurrentUser }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const { followings, followers } = useContext(FollowContext);
  const { userInfo } = useContext(UserContext);
  const [userFollowers, setUserFollowers] = useState([]);
  const [userFollowings, setUserFollowings] = useState([]);
  const { addToast } = useToast();
  const [isFollowModalOpen, setIsFollowModalOpen] = useState(false);
  const [modalUsers, setModalUsers] = useState([]);
  const [title, setTitle] = useState('');

  useEffect(() => {
    setUserFollowers([]);
    setUserFollowings([]);
    const loadFollows = async () => {
      try {
        const response = await fetchUserFollow(userId);
        setUserFollowers(response.follower);
        setUserFollowings(response.following);
      } catch (error) {
        console.error('Error fetching user follows:', error);
      }
    };
    if (!isCurrentUser) {
      loadFollows();
    } else {
      setUserFollowers(followers);
      setUserFollowings(followings);
    }
  }, [userId, isCurrentUser]);

    const handleFollow = async (userId) => {
        try {
            await sendFollowRequest(userId);
            addToast('Follow request sent', { type: 'success' });
        } catch (error) {
            console.error('Error sending follow request:', error);
        }
    };

  const renderUserList = (users, type) => {
    const handleShowAll = (users) => {
        setModalUsers(users);
        setTitle(`${userName}'s ${type}`);
        setIsFollowModalOpen(true);
    }
    if (!users || users.length === 0) {
        return null;
    }
    return (
        <div className="subject-rel user-follows">
        <h2 className='subject-rel-title'>{type}</h2>
        {users?.slice(0, 3).map((user) => {
            const isFollowing = followings.some(following => following.id === user.id);
            return (
            <div key={user.id} className="user-item">
                <CustomAvatar size={25} firstName={user.first_name} lastName={user.last_name}/>
                <div className="user-info">
                    <div className="user-name hover-to-green">
                        <CustomLink
                            to={profilePage(user.id, user.first_name, user.last_name)}
                        >
                            {user.first_name} {user.last_name}
                        </CustomLink>
                    </div>
                </div>
                {isAuthenticated && !isFollowing && user.id !== userInfo?.id &&
                    <button className='linko-button linko-button--tertiary' onClick={() => handleFollow(user.id)}>
                        Follow
                    </button>
                }
            </div>
            );
        })}
        {users?.length > 3 && 
            <div 
                className="show-all" 
                onClick={() => handleShowAll(users)}
            >
                Show all
            </div>
        }
        </div>
    );
  };

  return (
    <div className="user-follows">
      {renderUserList(userFollowers, 'Followers')}
      {/* {renderUserList(userFollowings, 'Followings')} */}
      <UserModal 
        isOpen={isFollowModalOpen} 
        onClose={() => setIsFollowModalOpen(false)} 
        users={modalUsers}
        title={title}
        followings={userFollowings}
      />
    </div>
  );
};

export default UserFollows;

