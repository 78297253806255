import React from 'react';
import PropTypes from 'prop-types';

const CustomAvatar = ({ size, firstName = '', lastName = '' }) => {
  const getInitials = (first, last) => {
    const firstInitial = first && first.length > 0 ? first[0].toUpperCase() : '';
    const lastInitial = last && last.length > 0 ? last[0].toUpperCase() : '';
    return firstInitial + lastInitial || '?';
  };

  const initials = getInitials(firstName, lastName);

  const avatarStyle = {
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: '50%',
    backgroundColor: '#48c78e',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFFFFF',
    fontWeight: '600',
    fontSize: `${size / 2.2 }px`,
    fontFamily: 'Arial, sans-serif',
  };

  return (
    <div style={avatarStyle}>
      {initials}
    </div>
  );
};

CustomAvatar.propTypes = {
  size: PropTypes.number.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

export default CustomAvatar;
