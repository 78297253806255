import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [canGoBack, setCanGoBack] = useState(window.history.length > 1);

  const goBack = () => {
    navigate(-1);
  };

  // Update canGoBack whenever location changes
  useEffect(() => {
    setCanGoBack(window.history.length > 1);
  }, [location]);

  return (
    <NavigationContext.Provider value={{ goBack, canGoBack }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};
