import React, {useEffect, useState, useRef, useContext} from 'react';
import { createRoot } from 'react-dom/client';
import { AddNoteCardContext } from '../../Context/AppContext';
import tippy, {hideAll} from 'tippy.js';
import { BsChatLeftQuote } from "react-icons/bs";
import { Readability } from '@mozilla/readability';

const ArticleReader = ({articleContent, resource}) => {

    const articleReaderRef = useRef(null);
    const [isTooltipInteracted, setIsTooltipInteracted] = useState(false);
    const { setNewNoteContent } = useContext(AddNoteCardContext);
    let tippyInstance = null;
    const [content, setContent] = useState({
        content: '',
    });
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        if (!articleContent) return;

        const parser = new DOMParser();
        const doc = parser.parseFromString(
            typeof articleContent === 'object' ? articleContent.content : articleContent, 
            'text/html'
        );

        // Add minimum content length check
        const contentText = doc.body.textContent.trim();
        if (contentText.length < 200) {
            setContent({ content: '' });
            return;
        }

        // Check if h1 exists and matches the resource title
        let titleElement = doc.querySelector('h1');
        let needNewTitle = false;

        if (!titleElement || !(titleElement.textContent.trim().includes(resource.title.trim()) || resource.title.trim().includes(titleElement.textContent.trim()))) {
            needNewTitle = true;
        }
        // Move any images that appear before the title to after it
        if (titleElement) {
            const images = doc.querySelectorAll('img');
            images.forEach(img => {
                const imgParent = img.parentElement;
                if (imgParent && titleElement.compareDocumentPosition(img) & Node.DOCUMENT_POSITION_PRECEDING) {
                    // If image is before title, move it after
                    titleElement.after(imgParent);
                }
            });
        }

        if (needNewTitle && resource?.title) {
            const newTitleElement = doc.createElement('h1');
            newTitleElement.textContent = resource.title;
            doc.body.insertBefore(newTitleElement, doc.body.firstChild);
            titleElement = newTitleElement;
        }

        // Check if metadata section exists
        let metadataDiv = doc.querySelector('.article-meta');
        if (!metadataDiv) {
            metadataDiv = doc.createElement('div');
            metadataDiv.className = 'article-meta';

            // Add author if available
            if (resource?.author) {
                const authorSpan = doc.createElement('span');
                authorSpan.textContent = `By ${resource.author}`;
                metadataDiv.appendChild(authorSpan);
            }

            // Add date if available
            if (resource?.year) {
                if (resource?.author) {
                    metadataDiv.appendChild(doc.createTextNode(' • '));
                }
                const dateSpan = doc.createElement('span');
                let date = resource.year;
                if (resource?.month) {
                    date = `${resource.month} ${date}`;
                }
                dateSpan.textContent = date;
                metadataDiv.appendChild(dateSpan);
            }

            // Insert metadata after title
            if (titleElement) {
                titleElement.after(metadataDiv);
            } else {
                doc.body.insertBefore(metadataDiv, doc.body.firstChild);
            }
        }

        // Handle embedded content
        handleEmbeddedContent(doc);
        
        if (typeof articleContent !== 'object') {
            const reader = new Readability(doc);
            const article = reader.parse();
            if (article) {
                setContent({
                    content: article.content,
                });
                return;
            }
        }

        setContent({
            content: doc.body.innerHTML,
        });
    }, [articleContent, resource]);

    // Add this new useEffect to handle click events
    useEffect(() => {
        const handleImageClick = (event) => {
            const img = event.target;
            if (img.tagName === 'IMG' && img.getAttribute('data-clickable') === 'true') {
                setSelectedImage(img.src);
            }
        };

        const articleContent = document.getElementById('articleContent');
        if (articleContent) {
            articleContent.addEventListener('click', handleImageClick);
        }

        return () => {
            if (articleContent) {
                articleContent.removeEventListener('click', handleImageClick);
            }
        };
    }, []);

    const handleEmbeddedContent = (doc) => {
        // Remove empty elements
        const removeEmptyElements = (element) => {
            // First recursively process all children
            const children = Array.from(element.children);
            children.forEach(child => removeEmptyElements(child));
            
            // Check if element is empty AND doesn't contain any meaningful content
            if (element.children.length === 0 && 
                !element.textContent.trim() && 
                element.tagName.toLowerCase() !== 'img' && // Don't remove img elements
                !element.querySelector('img') && 
                !element.querySelector('iframe') &&
                element !== doc.body) {
                element.parentNode?.removeChild(element);
            }
        };
        
        removeEmptyElements(doc.body);

        // Remove links from images that are wrapped in anchor tags
        const linkedImages = doc.querySelectorAll('a img');
        linkedImages.forEach(img => {
            const parentAnchor = img.closest('a');
            if (parentAnchor) {
                parentAnchor.replaceWith(img);
            }
        });

        // Handle Twitter embeds
        const tweets = doc.getElementsByClassName('twitter-tweet');
        Array.from(tweets).forEach(tweet => {
            if (!tweet.hasAttribute('data-processed')) {
                const tweetScript = doc.createElement('script');
                tweetScript.src = 'https://platform.twitter.com/widgets.js';
                tweetScript.async = true;
                tweet.appendChild(tweetScript);
                tweet.setAttribute('data-processed', 'true');
            }
        });

        // Handle Instagram embeds
        const instagrams = doc.getElementsByClassName('instagram-media');
        Array.from(instagrams).forEach(instagram => {
            if (!instagram.hasAttribute('data-processed')) {
                const instaScript = doc.createElement('script');
                instaScript.src = '//www.instagram.com/embed.js';
                instaScript.async = true;
                instagram.appendChild(instaScript);
                instagram.setAttribute('data-processed', 'true');
            }
        });

        // Handle YouTube embeds
        const youtubeFrames = doc.getElementsByTagName('iframe');
        Array.from(youtubeFrames).forEach(frame => {
            if (frame.src?.includes('youtube.com')) {
                frame.setAttribute('id', '_linko_youtube_video');
                frame.style.width = '100%';
                frame.style.aspectRatio = '16/9';
            }
        });
    };

    const handleMouseUp = () => {
        const selection = window.getSelection();
        const text = selection?.toString().trim();
        const articleContent = document.getElementById('articleContent');

        // Check if there's a valid selection and it's within the article content
        if (!text || !articleContent?.contains(selection?.anchorNode)) {
            if (!isTooltipInteracted) hideAll();
            return;
        }

        hideAll();

        // Add check for valid selection range
        if (!selection || selection.rangeCount === 0) return;

        tippyInstance = tippy(articleContent, {
            showOnCreate: true,
            hideOnClick: false,
            trigger: 'manual',
            placement: 'bottom',
            interactive: true,
            theme: 'heavy-shadow',
            getReferenceClientRect: () => {
                try {
                    const range = selection.getRangeAt(0);
                    return range.getBoundingClientRect();
                } catch (error) {
                    // Fallback to a default position if range is invalid
                    return articleContent.getBoundingClientRect();
                }
            },
            onHide: () => {
                setIsTooltipInteracted(false);
            }
        });

        const div = document.createElement('div');
        div.style.display = 'flex';
        div.style.alignItems = 'center';
        div.style.columnGap = '8px';
        div.style.padding = '8px 12px';
        div.style.cursor = 'pointer';
        div.style.border = '1px solid #e0e0e0';
        div.style.borderRadius = '6px';
        div.style.backgroundColor = '#ffffff';
        div.style.transition = 'all 0.2s ease';

        // Add hover effect
        div.addEventListener('mouseenter', () => {
            div.style.backgroundColor = '#f5f5f5';
            div.style.borderColor = '#d0d0d0';
        });

        div.addEventListener('mouseleave', () => {
            div.style.backgroundColor = '#ffffff';
            div.style.borderColor = '#e0e0e0';
        });

        const p = document.createElement('p');
        p.textContent = 'Quote';
        p.style.margin = '0px';
        p.style.color = '#4a4a4a';

        const iconContainer = document.createElement('span');
        const root = createRoot(iconContainer);
        
        iconContainer._root = root;
        
        root.render(<BsChatLeftQuote />);
        iconContainer.style.width = '20px';
        iconContainer.style.height = '20px';
        iconContainer.style.fontSize = '18PX';
        iconContainer.style.marginTop = '2px';

        div.appendChild(iconContainer);
        div.appendChild(p);
        
        p.addEventListener('click', () => {
            setIsTooltipInteracted(true);
            handleConfirm(text);
            tippyInstance.hide();
        });
    
        tippyInstance.setContent(div);

        tippyInstance.setProps({
            onHidden: () => {
                if (iconContainer._root) {
                    iconContainer._root.unmount();
                }
            }
        });
    };

    const handleConfirm = (text) => {
        setNewNoteContent(text);
        tippyInstance?.hide();
    }

    // Add modal close handler
    const handleCloseModal = () => {
        setSelectedImage(null);
    };

    if (!articleContent) return null;

    return (
        <>
            <div className='subject-rel-title' style={{marginTop: '0px'}}>Read</div>
            <div className='article-reader' 
                ref={articleReaderRef}
                onMouseUp={handleMouseUp}
            >
                <div 
                    className="article-content"
                    dangerouslySetInnerHTML={{ __html: content.content }} 
                    id='articleContent'
                />
            </div>

            {/* Update Image Modal */}
            {selectedImage && (
                <div className="image-modal" onClick={handleCloseModal}>
                    <img 
                        src={selectedImage} 
                        alt="Enlarged view" 
                        onClick={handleCloseModal}
                    />
                </div>
            )}
        </>
    );
};

export default ArticleReader;
