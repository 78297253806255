import React from 'react';
import ResourceTypeIcon from '../ResourceCard/ResourceTypeIcon';

const ResourceThumbnailPlaceholder = ({type, width, height}) => {
    const numericWidth = width ? parseInt(width, 10) : 0;
    const numericHeight = height ? parseInt(height, 10) : 0;
    const iconSize = Math.min(numericWidth, numericHeight) * 0.5;

    return (
        <div 
            className='resource-thumbnail-placeholder' 
            style={{
                width: width,
                height: height,
                boxSizing: 'border-box',
                padding: 0,
                margin: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: width,
                minHeight: height,
                maxWidth: width,
                maxHeight: height,
                flexShrink: 0,
                flexGrow: 0,
                overflow: 'hidden' 
            }}
        >
            <div 
                className='resource-thumbnail-placeholder-background' 
                style={{
                    width: '100%', 
                    height: '100%', 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center'
                }}
            >
                <ResourceTypeIcon type={type} size={iconSize} color='#ccc' />
            </div>
        </div>
    );
};

export default ResourceThumbnailPlaceholder;