import axios from "axios";
import storageUtils from "../storageUtils.js";

let isRefreshing = false;
let failedQueue = [];
let isHandlingSession = false;

// axios.defaults.baseURL = "http://127.0.0.1:8000/";
// axios.defaults.baseURL = "http://192.168.1.164:8000/";
// axios.defaults.baseURL = "http://192.168.1.219:8000/";
// axios.defaults.baseURL = "http://127.0.0.1:9000/";


const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const handleSessionExpiration = async () => {
  if (isHandlingSession) return;
  isHandlingSession = true;

  try {
    // Clear all tokens and auth headers
    await storageUtils.removeItem("access_token");
    await storageUtils.removeItem("refresh_token");
    delete axios.defaults.headers.common["Authorization"];
    
    // Clear any pending requests
    processQueue(new Error('Session expired'), null);
    failedQueue = [];
    
    // Emit event
    if (typeof window !== 'undefined') {
      window.dispatchEvent(new Event('session-expired'));
    } else {
      EventEmitter.emit('session-expired');
    }
  } finally {
    isHandlingSession = false;
    isRefreshing = false;
  }
};

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    
    // If we're already handling session expiration, reject immediately
    if (isHandlingSession) {
      return Promise.reject(error);
    }

    const status = error.response?.status;
    const errorData = error.response?.data;
    
    // Handle refresh token request failure
    if (originalRequest?.url === "/api/auth/refresh/") {
      await handleSessionExpiration();
      return Promise.reject(error);
    }

    const publicRoutes = [
      "/api/auth/register/",
      "/api/auth/activate/",
      "/api/auth/reset_password/",
      "/api/auth/login/",
      "/api/auth/logout/",
    ];

    const isInvalidTokenError = 
      status === 401 && 
      errorData?.code === "token_not_valid" &&
      errorData?.messages?.[0]?.message === "Token is invalid or expired";

    // Handle 401 errors for non-public routes
    if ((status === 401 && !publicRoutes.includes(originalRequest?.url)) || isInvalidTokenError) {
      if (isRefreshing) {
        // Queue the request if we're already refreshing
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch(() => {
            return Promise.reject(error);
          });
      }

      isRefreshing = true;

      try {
        const refreshToken = await storageUtils.getItem("refresh_token");
        if (!refreshToken) {
          throw new Error("No refresh token available");
        }

        const refreshAxios = axios.create();
        const response = await refreshAxios.post("/api/auth/refresh/", 
          { refresh: refreshToken },
          { headers: { "Content-Type": "application/json" } }
        );

        const { access, refresh } = response.data;
        await storageUtils.setItem("access_token", access);
        await storageUtils.setItem("refresh_token", refresh);
        axios.defaults.headers.common["Authorization"] = `Bearer ${access}`;
        
        processQueue(null, access);
        originalRequest.headers["Authorization"] = "Bearer " + access;
        return axios(originalRequest);
      } catch (err) {
        processQueue(err, null);
        await handleSessionExpiration();
        return Promise.reject(error);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

export default axios;