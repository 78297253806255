import React, { useState, useContext } from 'react';
import { putMySubject, deleteMySubject, subjectPage } from '@linko/shared_utils';
import { UserContext } from '../../Context/AppContext';

const EditCustomTag = ({
    subjectName,
    newSubjectName,
    setNewSubjectName,
    description,
    setDescription,
    setEditTag,
    subjectId,
    safeNavigate,
    setPersistentMessage,
    requestConfirm,
}) => {

    const { fetchUserSubjectData } = useContext(UserContext);

    const [newDescription, setNewDescription] = useState(description);

    const editTagName = async (e) => {
        e.preventDefault();

        if ( newSubjectName.trim() === '') {
            alert('Name cannot be empty');
            setNewSubjectName(subjectName);
            return;
        }
        try {
            await putMySubject(subjectId, newSubjectName, newDescription);
            setEditTag(false);
            setDescription(newDescription);
            if (newSubjectName !== subjectName) {
                safeNavigate(subjectPage(newSubjectName, true));
            }
            fetchUserSubjectData();
        } catch (error) {
            console.error("Failed to save new subject name:", error);
        }
    };

    const deleteTag = async () => {
        requestConfirm(
            'This tag will be removed from all notes that has attached to it. Are you sure you want to delete it? This action cannot be undone.',
        async () => {   
            try {
                await deleteMySubject(subjectId);
                await fetchUserSubjectData();
                safeNavigate('/my_linko');
                setPersistentMessage( `You have successfully deleted ${subjectName}`, { appearance: 'success' });
            } catch (error) {
                console.error("Failed to delete tag:", error);
            }
        },
        null,
        'Delete'
        );
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    const handleCancel = () => {
        setNewSubjectName(subjectName);
        setDescription(description);
        setEditTag(false);
    }

    return (
        <form onSubmit={editTagName} autoFocus className='edit-cus-label-form'>
            <input className='cus-label-name' value={newSubjectName} onChange={(e) => setNewSubjectName(e.target.value)} autoFocus/>
            <textarea className='cus-label-description' value={newDescription} onChange={(e) => setNewDescription(e.target.value)} onKeyDown={handleKeyDown} placeholder='Add a description'/>
            <div className='buttons'>
                <button onClick={deleteTag} className='linko-button linko-button--danger'>Delete tag</button>
                <button onClick={handleCancel} className='linko-button linko-button--cancel'>Cancel</button>
                <button onClick={editTagName} className='linko-button linko-button--primary'>Save</button>
            </div>
        </form>
    );
};

export default EditCustomTag;