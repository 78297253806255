import React, { useState, useEffect, useRef, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; 
import { FaCircleCheck } from "react-icons/fa6";
import { putMyResource } from '@linko/shared_utils';
import { HomePageContext } from '../../Context/HomePageContext';

const ResourceProgress = ({
    id, 
    learnHistory, 
    onUpdate
}) => {
    const { updateSingleResourceInLibrary } = useContext(HomePageContext);
    if (!learnHistory || !learnHistory.resource) {
        return null;
    }
    const {
        note_count,
        progress,
        total_length,
    } = learnHistory;
    const [isFinished, setIsFinished] = useState(progress === total_length);
    const [settingProgress, isSettingProgress] = useState(false);
    const [setProgress, setSetProgress] = useState(progress);
    const [setTotalLength, setSetTotalLength] = useState(total_length);
    const progressPortion = Math.floor((setProgress / setTotalLength) * 100);
    const location = useLocation();
    const shareView = location.pathname.startsWith('/profile/');
    const [isTouchDevice, setIsTouchDevice] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [recentlyDragged, setRecentlyDragged] = useState(false);
    const progressBarRef = useRef(null);

    useEffect(() => {
        const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        setIsTouchDevice(isTouch);

        // Cleanup function to remove event listeners
        // return () => {
        //     document.removeEventListener('mousemove', handleMouseMove);
        //     document.removeEventListener('mouseup', handleMouseUp);
        // };
    }, []);

    const handleSetProgress = async (setProgress, setTotalLength) => {
        event.preventDefault();
        if (setProgress > setTotalLength || setProgress < 0 || setTotalLength < 0 || setTotalLength === 0 || isNaN(setProgress) || isNaN(setTotalLength)) {
            window.alert("Please enter a valid progress value.");
            return;
        }
        try {
            await putMyResource(id, setProgress, setTotalLength, null, null);
            if (onUpdate) {
                const updatedResourceDetails = {
                    ...learnHistory, 
                    progress: setProgress, 
                    total_length: setTotalLength
                };
                onUpdate(updatedResourceDetails); 
            }
            isSettingProgress(false);
            setIsFinished(setProgress === setTotalLength);
            updateSingleResourceInLibrary(id);
        } catch (error) {
            setSetProgress(progress);
            setSetTotalLength(total_length);
            isSettingProgress(false);
        }
    }
    

    const initialSettingProgress = () => {
        setIsFinished(false);
        setSetProgress(0);
        setSetTotalLength(100);
        isSettingProgress(true);
    }
    
    const calculateProgressFromMousePosition = (clientX) => {
        const rect = progressBarRef.current.getBoundingClientRect();
        const relativeX = Math.max(0, Math.min(clientX - rect.left, rect.width));
        const percentage = (relativeX / rect.width) * 100;
        const newProgress = Math.round((percentage / 100) * setTotalLength);
        return Math.min(newProgress, setTotalLength);
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (isFinished || settingProgress) return;
        
        const handleDrag = (e) => {
            const newProgress = calculateProgressFromMousePosition(e.clientX);
            setSetProgress(newProgress);
        };

        const handleDragEnd = async (e) => {
            document.removeEventListener('mousemove', handleDrag);
            document.removeEventListener('mouseup', handleDragEnd);
            setIsDragging(false);
            setRecentlyDragged(true);
            
            const newProgress = calculateProgressFromMousePosition(e.clientX);
            await handleSetProgress(newProgress, setTotalLength);

            setTimeout(() => {
                setRecentlyDragged(false);
            }, 100);
        };

        setIsDragging(true);
        document.addEventListener('mousemove', handleDrag);
        document.addEventListener('mouseup', handleDragEnd);
    };

    const handleProgressBarClick = (e) => {
        if (!isDragging && !recentlyDragged) {
            isSettingProgress(true);
        }
    };

    if (shareView) {
        return ;
    }

    return (
        <div className="resource-progress">
            <div style={{ display:'flex', alignItems:'center', columnGap:'5px'}} >
                <p className="note-count">
                    {note_count} note
                    {note_count !== 0 && note_count !== 1
                        ? "s"
                        : ""}
                </p>
            </div>
            {isFinished && !settingProgress &&
            <Tippy 
                content="Edit progress"
                placement="bottom"
                arrow={true}
                theme='light-border'
                hideOnClick={true} 
                delay={[0, 0]} 
                touch={false}
                disabled={isTouchDevice}
            >
                <div  
                    onClick={() => initialSettingProgress()}
                    style={{height:'fit-content', display:'flex', alignItems:'center', cursor:'pointer'}}
                    >
                    <FaCircleCheck className="resource-check-icon" />
                </div>
            </Tippy>
            }
            {!isFinished && 
            (settingProgress ? (
                <>
                    <form className='set-progress-form' role='form'>
                        <input
                            type="number"
                            value={setProgress}
                            onChange={(e) =>
                                setSetProgress(parseInt(e.target.value))}
                            className="progress-input"
                        />
                        <span>/</span>
                        <input
                            type="number"
                            value={setTotalLength}
                            onChange={(e) =>
                                setSetTotalLength(parseInt(e.target.value))}
                            className="progress-input"
                        />
                        <button onClick={() => handleSetProgress(setProgress, setTotalLength)} className='linko-button linkno-button--cancel'>Save</button>
                        <button onClick={() => handleSetProgress(setTotalLength, setTotalLength)} title='Check as finised' className='linko-button linkno-button--cancel'><FaCircleCheck/> Finished</button>
                    </form>
                </>
            ) : (
                <Tippy 
                    content="Edit progress"
                    placement="bottom"
                    arrow={true}
                    theme='light-border'
                    hideOnClick={true} 
                    delay={[0, 0]} 
                    touch={false}
                    disabled={isTouchDevice}
                >
                    <div 
                        className={`progress-bar-wrapper ${isDragging ? 'dragging' : ''}`} 
                        role='resourceprogressbar' 
                        onClick={handleProgressBarClick}  
                        data-testid="resourceProgress"
                    >
                        <div className="progress-bar" ref={progressBarRef}>
                            <div
                                className="progress-bar-fill"
                                style={{ width: `${progressPortion}%` }}
                            >
                                <div 
                                    className="progress-drag-dot"
                                    onMouseDown={handleMouseDown}
                                    style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
                                />
                            </div>
                        </div>
                        <div className="progress-portion">{progressPortion}%</div>
                    </div>
                </Tippy>
            ))}
        </div>
    );
};

export default ResourceProgress;
